var $ = require('jquery');

module.exports = function(url, record_visit, undefined){ // onRenderPage, url, record_visit, undefined
	var html = $(this.next_page.html);
	// update meta
	$('head meta').filter('[name="description"], [name="keywords"], [property="og:image"]').remove();
	html.find('meta').filter('[name="description"], [name="keywords"], [property="og:image"]').insertAfter('head meta[name="viewport"]');
	$('head title').text(html.find('title').first().text());
	
	// update GA
	if ( record_visit === undefined || record_visit === true ) {
		// Visit record
		console.log('record visit');
		// recordVisit(this.prev_page.url || '', this.next_page.url);
		if ( typeof _gaq != 'undefined' ) {
			console.log('gaq');
			_gaq.push(['_trackPageview'], this.next_page.url);
		}

		if ( window['GoogleAnalyticsObject'] && window[window['GoogleAnalyticsObject']] ) {
			console.log('ga');
			window[window['GoogleAnalyticsObject']]('send', 'pageview');
		}

		if ( window['GAnonymObject'] && window[window['GAnonymObject']] ) {
			console.log('gano');
			window[window['GAnonymObject']]('send', 'pageview');
		}

		// if ( !this.firstPass && window.Base64UrlEncode ) {
		// 	new Image().src = SITE_ROOT+'visitgarecord/v'+Base64UrlEncode(this.prev_page.url || '')+'/v'+Base64UrlEncode(this.next_page.url)+'/'+Math.round(Math.random()*99999999);
		// }
		// if ( !this.firstPass && window.Base64UrlEncode ) {
		if ( window.Base64UrlEncode ) {
			console.log('cube');
			new Image().src = SITE_ROOT+'visitrecord/v'+Base64UrlEncode(this.prev_page.url || '')+'/v'+Base64UrlEncode(this.next_page.url)+'/'+Math.round(Math.random()*99999999);
		}
	}
};