var $ = require('jquery');
var NProgress = require('nprogresscustom');
var mq = require('mq');

var progress = function (el, n) {
	if ( mq( -767 ) ) {
		$('#progbar').get(0).style.width = (n*100)+'%';
	}
	else {
		TweenMax.to($('#progbar'), 0.2, {
			width: (n*100)+'%',
			ease: Power2.easeOut
		});
	}
};

var done = function (el) {
	if ( mq( -767 ) ) {
		setTimeout(function() {
			$('#progbar').get(0).style.opacity = 0;
		}, 300);
		setTimeout(function() {
			$('#progbar__wrap').hide();
			$('#progbar').get(0).style.opacity = 1;
			$('#progbar').get(0).style.width = 0;
		}, 600);
	}
	else {
		TweenMax.to($('#progbar'), 0.2, {
			opacity: 0,
			ease: Power2.easeOut
		});
	}
};

module.exports = function(){
	NProgress.configure({
		cb: progress,
		cbDone: done,
		cbEl: ''
	});
	if ( mq( -767 ) ) {
		$('#progbar__wrap').show();
	}
	else {
		TweenMax.set($('#progbar'), {
			width: 0,
			opacity: 1
		});
	}
	NProgress.start();
};