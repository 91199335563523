var $ = require('jquery');

module.exports = function(evt, el, url){
	if ( $(el).attr('target') == '_blank' ) return false;
	if ( $(el).attr('data-no-ajax') == '1' ) return false;
	if ( $(el).attr('role') == 'button' ) {
		evt.preventDefault();
		return false;
	}
	// if nothing to block, return true to contiue the others processes
	return true;
};