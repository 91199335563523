(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		require('gsap');
		require('gsap18/utils/SplitText.js');
		module.exports = factory(
			window,
			require('jquery'),
			require('cymasmooth'),
			require('utils'),
			require('./site.js'),
			// require('imagesloadedwithsize'),
			// require('./scrollmagic-anims.js'),
			// require('./frise.js'),
			// require('formcheck'),
			// require('./team-items.js'),
			// require('form-input-placeholder'),
			require('resize-wo-toolbar'),
			// require('scrollmenu'),
			// require('./anims/anims.js'),
			require('ismobile'),
			require('./anims/0reveals-manager.js'),
			require('./menu-toggler.js')
			// require('slick'),
			// require('nanoscroller'),
			// require('lightslide')
		);
	} else {
		factory(window, jQuery, cymasmooth, scrollMagicAnims, FormCheck);
	}

}(window, function(window, $, cymasmooth, UTILS, site, realResize, is_mobile, revealManager, menuToggler) {

// $(window).resize(function(){
realResize.add(function(w, h){
	$('.fh').css({
		'height': h + 'px',
		'min-height': 'auto'
	});
	document.documentElement.style
	    .setProperty('--vh', h + 'px');
})

var pageInit = function(next) {

	// scrollMagicAnims.destroy();
	// scrollMagicAnims.init(next);
	
	// $(window).trigger('resize');

	// site.unfreezeUI();
	site.initPage( next );
};

var first_loading = true;
var hideAnim = function(next_page, prev_page){
	var tl = new TimelineMax({
		paused : true,
		onComplete: function(){
			// $('[data-js="site-loading"]').remove();
			// $(old).remove();
			// alert('done')
			// TweenMax.set(next, {'className':'-=is-hidden',clearProps:'all'});
			// TweenMax.set($('.page__rect__mover').get(0), {'className':'-=is-hidden',clearProps:'all'});
		}
	})
	console.log('prev_page', prev_page);
	console.log('next_page', next_page);
	if ( prev_page.model == 'prodcatmodel' && next_page.model == 'prodmodel' ) {
		$ref = $( '.link-with-mover-effect' );
		// alert( $ref.size(  ) )
		if ( $ref.size() ) {
			var $el = $( '<div class="trans-mover" />' )
				.css( {
					top: $ref.offset().top - $(window).scrollTop(),
					left: $ref.offset().left,
					width: $ref.width(),
					height: $ref.height(),
					opacity: 0
				} )
				.appendTo( $( 'body' ) )
			console.log('add trans mover');

			$ref.parent(  ).find( '.prod__cat__item__img' ).clone(  )
				.appendTo( $el )
		}
	}
	// tl.add(function(){
	// 	$('.foot__ctas__home').removeClass('is-active');
	// 	$('.foot__page__name').removeClass('is-active');
	// 	$('.foot__slide__arrows').removeClass('is-active');
	// });
	return tl;
};



var getShowNextPageAnim = function(el, old){
	var timeline = new TimelineMax({
		// paused: true
	});
	timeline.addLabel('getShowNextPageAnim0');
	timeline.add(function(){
		$(el).css({visibility:'visible'});
	}, 'getShowNextPageAnim0');
	// var appear_duration = 0.007;
	// if ( $( '.trans-mover' ).size() ) {
	// 	appear_duration = 0.35;
	// }
	// timeline.to(el, 0, {
	// 	opacity: 1
	// }, 'getShowNextPageAnim0');
	
	// if ( first_loading ) {
	// 	timeline.to($('[data-js="site-loader"]'), 0.007, {
	// 		opacity: 0
	// 	}, 'getShowNextPageAnim0');
	// }
	timeline.addLabel('getShowNextPageAnim');
	// timeline.to(window, 0, {
	// 	scrollTo: 0
	// }, 'getShowNextPageAnim');
	// timeline.add(function(){
	// 	$('html, body').scrollTop(0);
	// }, 'getShowNextPageAnim');
	// timeline.add(function(){
	// }, 'getShowNextPageAnim');
	timeline.add(function(){
		$('html, body').scrollTop(0);
		// $(old).remove();
		// $(el).css({position:'relative'});
	}, 'getShowNextPageAnim');
	return timeline;
};

var createAnimNextDiv = function( el ){
	return $( el ).clone().css({
		opacity: 0,
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		// visibility: 'visible',
		zIndex: 5
	}).appendTo($('[data-js="content-wrap"]')).get(0);
};

var createAnimTimeline = function( el ){
	return new TimelineMax({
		paused : true,
		onComplete: function(){
			$('[data-js="site-loading"]').remove();
			$('[data-js="site-loader"]').remove();
			$('.trans-mover').remove();
			// $( '.link-with-mover-effect' ).removeClass( 'link-with-mover-effect' );
			console.log('on complete final');
			// $(old).remove();
			// alert('done')
			// TweenMax.set(next, {'className':'-=is-hidden',clearProps:'all'});
			// TweenMax.set($('.page__rect__mover').get(0), {'className':'-=is-hidden',clearProps:'all'});
		}
	});
};

var showAnim = function(next_page, prev_page){
	console.log('prev_page', prev_page);
	console.log('next_page', next_page);
	$('body').removeClass('body__'+prev_page.model).addClass('body__'+next_page.model);

	// $(window).trigger('cymasmooth:initOfflineVideo');
	
	var old = $(this.contentQuery).get(0);
	var next = false;
	var change_proj_filter = false;
	// if ( prev_page.model == null ) {
	// 	next = old;
	// 	old = false;
	// 	$(this.contentQuery).not('[data-url="' + next_page.url + '"]').remove();
	// }
	// else {
		if ( this.prev_page.model == 'projetsmodel' && this.next_page.model == 'projetsmodel' ) {
			// ne pas cloner toute la div mais seulement l'interieur de la grille
			// next = juste le nouveau contenu
			// old = l'ancien juste le contenu
			old = $(old).find('.page__section--projets').get(0);
			next = $( next_page.div ).find('.page__section--projets').clone().css({
				opacity: 0,
				display: 'none',
				// position: 'fixed',
				// top: 0,
				// left: 0,
				// right: 0,
				// visibility: 'visible',
				zIndex: 5
			}).insertBefore( old ).get(0);
			change_proj_filter = true;
			// 
			// maj le sous menu des filtres
			var index = $( next_page.div ).find('.projs__filters__item .is-current').parent().index();
			$('.projs__filters__item').find('.is-current').removeClass('is-current');
			$('.projs__filters__item').eq( index ).find('a').addClass('is-current');
		}
		else {
			next = createAnimNextDiv( next_page.div );
		}
	// }
	$(window).trigger('resize');

	// alert( 'appended' )
	// setTimeout(function() {
	// 	if(typeof addthis !== 'undefined' && addthis.layers && addthis.layers.refresh) { addthis.layers.refresh(); }
	// 	console.log('addthis', addthis);
		
	// }, 100);


	var ww = $(window).width();
	var wh = $(window).height();
	var tl = createAnimTimeline();
	tl.addLabel('prestart', 0);
	tl.add( function(){
		// alert('b')
		$(next).get(0).offsetWidth;
		$(window).trigger('resize');
	}, 'prestart+=0.1' );
	// return tl;
	// tl.addLabel('transstart', 'prestart+=0.2' );

	// tl.to( $(next), 0, {
		// opacity: 1
	// }, 'transstart+=1.2');
	// }, 'transstart');

	// if ( ww < wh ) {
	// 	getHorizontalTransition.call( this, tl, next_page, prev_page, old, next, ww, wh );
	// }
	// else {
	// 	getVerticalTransition.call( this, tl, next_page, prev_page, old, next, ww, wh );
	// }

	tl.to( old, 0.4, {
		opacity: 0,
		ease: Power0.easeOut
	}, 'prestart+=0.2' );

	if ( change_proj_filter ) {
		tl.add( function(){
			$('html, body').animate({scrollTop:0}, 400);
		}, 'prestart+=0.2' );
	}

	tl.add( getShowNextPageAnim( next, old ), '+=0.005' );
	// tl.add( function(){
	// 	// alert('b')
	// 	$(next).get(0).offsetWidth;
	// 	$(window).trigger('resize');
	// }, 'prestart+=0.1' );
	
	// if ( old !== false ) {
	// 	// tl.to( $(old).find('[data-js-emresize]'), 1, {
	// 	// 	opacity: 0
	// 	// } )
	// 	tl.to( $(next), 0.7, {
	// 		opacity: 1,
	// 		ease: Power0.easeOut
	// 	} )
	// }

	// tl.add(function(){
	// 	$(old).remove();
	// 	$(next).css({position:'relative'});
	// }, '+=0.05');
	
	tl.add(function(){
		// alert('pageinit')
		pageInit(next);
		// $(window).trigger('sticky:resize');
	}, '+=0.05');

	tl.addLabel('nextmainpartstart', '+=0.1' );

	// if ( !head_visible ) {
	// 	head_visible = true;
	// 	if ( $('.head__logo').size() ) {
	// 		tl.fromTo( $('.head__logo'), 0.5, {
	// 			opacity: 0,
	// 			y: -30
	// 		}, {
	// 			opacity: 1,
	// 			y: 0,
	// 			ease: Power2.easeOut
	// 		} )
	// 	}
	// }
	


	// if ( this.prev_page.model == 'homemodel' || $('body').hasClass('is-main-menu-open') ) { // at this point, prev page is previous page
	// 	// custom anim here only if menu is closed
	// 	if ( !$('body').hasClass('is-main-menu-open') ) {
	// 		// set prev page above next page (next page is z index 5)
	// 		tl.add(function(){
	// 			$(old).css({zIndex:6})
	// 			$(next).css({opacity:1})
	// 		}, '+=0.05');

	// 		tl.addLabel('tssss', '+=0.05');

	// 		tl.to( $(old).find('.home__nav__items'), 1, {
	// 			opacity: 0,
	// 			ease: Power0.easeIn
	// 		}, 'tssss' )

	// 		// tl.to( $(old).find('.home__nav__items'), 1, {
	// 		// 	scale: 1.15,
	// 		// 	ease: Power2.easeIn
	// 		// }, 'tssss' )

	// 		tl.set( $('.page__loader__inner'), {
	// 			y: '0%'
	// 		}, '+=0.05' )

	// 		tl.add(function(){
	// 			$(old).remove();
	// 			$(next).css({position:'relative'});
	// 		}, '+=0.05');

	// 		tl.to( $('.page__loader__inner'), 1, {
	// 			// height:'100%',
	// 			y:'100%',
	// 			// opacity:1,
	// 			ease: Power3.easeInOut
	// 		}, '+=0.05' );


			
	// 	}
	// 	else { // coming from menu
	// 		tl.add(function(){
	// 			$(next).css({opacity:1})
	// 			$(old).remove();
	// 			$(next).css({position:'relative'});
	// 		}, '+=0.05');

	// 		tl.to( $('.head__menu__items'), 1, {
	// 			opacity: 0,
	// 			ease: Power0.easeIn
	// 		}, '+=0.05' )

	// 		tl.set( $('.page__loader__inner'), {
	// 			y: '0%'
	// 		}, '+=0.05' )

	// 		// need to clean menu
	// 		tl.add(function(){
	// 			TweenMax.set( $('.head__menu__items .head__menu__item'), {x:'0%'} );
	// 			menuToggler( /*fast = */true );
	// 			$('.head__menu__items').css({opacity:1})
	// 				.find('.head__menu__item').css({opacity:1})
	// 				.find('.is-active').removeClass('is-active');

	// 		}, '+=0.05');


	// 		tl.to( $('.page__loader__inner'), 1, {
	// 			// height:'100%',
	// 			y:'100%',
	// 			// opacity:1,
	// 			ease: Power3.easeInOut
	// 		}, '+=0.05' );
	// 	}
	// }
	// else {
		tl.add(function(){
			$(next).css({opacity:1,display:'block'})
			$(old).remove();
			$(next).css({position:'relative'});
		}, '+=0.05');
		// if ( this.next_page.model == 'homemodel' ) {
		// 	tl.set( $('.page__loader__inner'), {
		// 		// height:'100%',
		// 		y:'100%',
		// 		// opacity:1,
		// 		ease: Power3.easeInOut
		// 	} );
		// 	tl.addLabel('itemsanims', '+=0.05');
		// 	if ( $(window).width() < 768 ) {
		// 		tl.from( $( next ).find('.home__nav__items .head__menu__item').eq(0), 1.1, {
		// 			// height:'100%',
		// 			opacity:0,
		// 			y:'20%',
		// 			// opacity:1,
		// 			ease: Power2.easeOut
		// 		}, 'itemsanims+=0.1' );
		// 		tl.from( $( next ).find('.home__nav__items .head__menu__item').eq(1), 1.2, {
		// 			// height:'100%',
		// 			opacity:0,
		// 			// x:'20%',
		// 			// opacity:1,
		// 			ease: Power2.easeOut
		// 		}, 'itemsanims+=0.0' );
		// 		tl.from( $( next ).find('.home__nav__items .head__menu__item').eq(2), 1.1, {
		// 			// height:'100%',
		// 			opacity:0,
		// 			y:'-20%',
		// 			// opacity:1,
		// 			ease: Power2.easeOut
		// 		}, 'itemsanims+=0.1' );
		// 	}
		// 	else {

		// 		tl.from( $( next ).find('.home__nav__items .head__menu__item').eq(0), 1.1, {
		// 			// height:'100%',
		// 			opacity:0,
		// 			x:'20%',
		// 			// opacity:1,
		// 			ease: Power2.easeOut
		// 		}, 'itemsanims+=0.1' );
		// 		tl.from( $( next ).find('.home__nav__items .head__menu__item').eq(1), 1.2, {
		// 			// height:'100%',
		// 			opacity:0,
		// 			// x:'20%',
		// 			// opacity:1,
		// 			ease: Power2.easeOut
		// 		}, 'itemsanims+=0.0' );
		// 		tl.from( $( next ).find('.home__nav__items .head__menu__item').eq(2), 1.1, {
		// 			// height:'100%',
		// 			opacity:0,
		// 			x:'-20%',
		// 			// opacity:1,
		// 			ease: Power2.easeOut
		// 		}, 'itemsanims+=0.1' );
		// 	}
		// 	tl.from( $( next ).find('.foot__items__wrap__mask--left, .foot__items__wrap__mask--right'), 1, {
		// 		x: '0%',
		// 		ease: Power2.easeOut
		// 	}, '-=0.5' )
		// }
		// else {
			tl.to( $('.page__loader__inner'), 1, {
				// height:'100%',
				y:'100%',
				// opacity:1,
				ease: Power3.easeInOut
			} );
			
		// }
	// }



	// var has_reveal = revealManager( next_page.model + 'anim', $(next), tl, 'nextmainpartstart', old ); 

	first_loading = false;

	

	return tl;
};

var head_visible = false;

var f = function() { return false; };
var showAnimOld = function(next_page, prev_page){
	var $old = $(this.contentQuery);
	$next = $(next_page.div).clone().css({
		'opacity': 0,
		'position': 'fixed',
		'left': 0,
		'right': 0
	}).appendTo($old.parent());


	var ret = [];
	ret.push([$old, {'opacity': 0}, 1000, function(){$(this).remove();}, 'cubic:out', 200]);
	ret.push([$next, {'opacity': 1}, 1000, function(){
		$('html, body').scrollTop(0);
		$(this).css('position', 'static');
	}, 'cubic:out', 1000]);
	// alert(this.contentQuery)
	// alert(prev_page.uid)
	// alert('show')
	// return [];
	return ret;
};


// cymasmooth.registerAnim('homemodel_show', showHomeAnim);

// cymasmooth.registerAnim('pagemodel_show', showAnim);

cymasmooth.registerAnim('show', showAnim);

// cymasmooth.registerAnim('showold', showAnimOld);

// cymasmooth.registerAnim('hide', f);
cymasmooth.registerAnim('hide', hideAnim);

}));
