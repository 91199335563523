
// require('./loginanim.js');
// require('./homemodelanim.js');
// require('./axomodelanim.js');
// require('./film3dmodelanim.js');
// require('./brochuremodelanim.js');
// require('./contactmodelanim.js');
// require('./diapomodelanim.js'); 
// require('./dlsmodelanim.js');

// require('./reveals/pagehead.js'); 
// require('./reveals/titrebig.js'); 
// require('./reveals/titrebigsimple.js'); 
// require('./reveals/minititre.js'); 
// require('./reveals/fadein.js'); 
// require('./reveals/imagevertical.js'); 
// require('./reveals/aboutpeople.js'); 
// require('./reveals/waitform.js'); 
// require('./reveals/actuslistitem.js'); 
// require('./reveals/imagegrid.js'); 

// require('./reveals/lazyloader.js'); 

require('./reveals/home-boucle.js');
require('./reveals/home-title.js');
require('./reveals/scrolldown-mask.js');
require('./reveals/home-projs.js');
require('./reveals/home-pres.js');
require('./reveals/home-serv.js');
require('./reveals/home-clients.js');
require('./reveals/foot.js');
require('./reveals/projets-items.js');
require('./reveals/proj-head.js');
require('./reveals/proj-scrolldown.js');
require('./reveals/proj-txts.js');
require('./reveals/imagefromleft.js');
require('./reveals/imagefromright.js');
require('./reveals/proj-nav.js');
require('./reveals/ml-content.js');
// require('./reveals/projdetail.js'); 

var reveals = require('./0reveals.js');

module.exports = function(a, b, c, d, e, f){
    // console.log('get reveal anim', a, b);
    return reveals(a)(b, c, d, e, f);
};
