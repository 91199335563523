var $ = require('jquery');

var is_ui_freezed = false;

var blockScroll = function (event) {
	event.preventDefault();
	event.stopPropagation();
	return false;
};

var toggle = function(){
	is_ui_freezed = !is_ui_freezed;
	$('html')
		.toggleClass('is-ui-freezed', is_ui_freezed)
		[is_ui_freezed ? 'on' : 'off']("scroll touchmove mousewheel", blockScroll);
};

module.exports = toggle;

$(window).on('cymasmooth:onAjaxLoad', toggle);
$(window).on('cymasmooth:onEnd', toggle);