/****




******/

(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		// require('history');
		module.exports = factory(
			window,
			require('jquery'),
			require('utils'),
			require('ismobile')
		);
	} else {
		window.cymasmooth = factory(window, jQuery);
	}

}(window, function(window, $, utils, ismobile) {

var C = (function() {
	var cache = {};
	return function(selector, remove) {
		if (typeof remove !== 'undefined' && cache[selector]) {
			delete cache[selector];
		}
		if (!cache[selector]) {
			cache[selector] = document.getElementById(selector);
		}
		return cache[selector];
	};
})();

var can_use_history = false;
$(function(){
	// can_use_history = true;
});

var has_promise = (window.Promise);

var SkSmooth2 = function(){
	/**
	* Data stored for cache as `url => data`
	* @property pages
	* @type Array
	*/
	var pages = [];

	/**
	* Anims to be loaded matching current model
	* @property Anims
	* @type Object
	*/
	var Anims = {};
	var getAnim = function( model, way ){
		var key = way;
		if ( ismobile && Anims[ model + '_' + way + '_mobile' ] ) {
			key = model + '_' + way + '_mobile';
		}
		else if ( Anims[ model + '_' + way ] ) {
			key = model + '_' + way;
		}
		return Anims[key];
	};

	/**
	* Page to be hidden during page change Object with params : model,submodel,url,uid,div,subdiv
	* @property prev_page
	* @type Object
	*/
	this.prev_page = {'model':null,'submodel':null,'url':null,'uid':null,'subdiv':null,'div':null};
	
	/**
	* Page to be shown during page change Object with params : model,submodel,url,uid,div,subdiv
	* @property next_page
	* @type Object
	*/
	this.next_page = {'model':null,'submodel':null,'url':null,'uid':null,'subdiv':null,'div':null};

	/**
	* Element used to store data
	* @property cacheDiv
	* @type DomNode
	* @default div
	*/
	// var cacheDiv = new Element('div');
	var cacheDiv = document.createElement('div');
	// alert(cacheDiv)

	var funcs = {};
	var funcs_multi = {};

	/**
	* Div id to be shown/hidden during ajax loading
	* @property ajaxLoadingId
	* @type String
	* @default null
	*/
	var ajaxLoadingId = null;

	this.animatorObj = false;

	var sksmoothdebug = true;

	this.firstPass = true;
	this.pagesViewed = 0;

	this.enableHistory = function(val) {
		can_use_history = val;
	};

	this.registerFunc = function(name, func) {
		funcs[name] = func;
	};

	this.registerFuncMulti = function(name, func) {
		if ( !funcs_multi[name] ) {
			funcs_multi[name] = [];
		}
		funcs_multi[name].push( func );
	};

	this.registerAnim = function(name, func) {
		Anims[name] = func;
	};

	this.callFunc = function(name, a, b, c) {
		funcs[name](a, b, c);
	};

	this.defaultUrlQuery = 'intro';

	// this.contentQuery = '.site-content-inner';
	this.contentQuery = '[data-sksmooth="page-content"]';

	var that = this;

	this.processStep = function(name, a, b, c, d, e, f) {
		
		// un bloc comme ca a besoin de : 
		// 1 une fonction de preproc unique : funcs
		// console.log('funcs', name, funcs);
		this.processFunc(name, /* force_process = */true, a, b, c, d, e, f);
		// 2 des fonctions de preproc qui peuvent etre multiple : inutils cf event en dessous
		// 3 un event preproc sur window
		$(window).trigger('cymasmooth:' + name, a, b, c, d, e, f);
		// 
		// 4 une fonction bloquante unique
		// 5 des fonctions bloquantes
		if ( this.processFunc(name + 'Blocking', /* force_process = */false, a, b, c, d, e, f) ) {
			name += 'Done';
			// 8 une fonction de postproc unique : funcs
			// 8 un event postproc sur window
			this.processFunc(name, /* force_process = */true, a, b, c, d, e, f);
			$(window).trigger('cymasmooth:' + name, a, b, c, d, e, f);
			return true;
		}
		return false;

		// 6 une fonction avec callback unique (pas utile)
		// 7 des fonctions avec callback : pas possible

	};

	this.processFunc = function(name, force_process, a, b, c, d, e, f) {
		var ret = true;
		// if somthing return false, the whole method will return false
		if ( funcs[name] ) {
			ret = funcs[name].call(this, a, b, c, d, e, f);
		}
		// console.log('funcs_multi', name, funcs_multi);
		if ( ( ret || force_process ) && funcs_multi[name] ) {
			// console.log('length', funcs_multi[name].length );
			for(var i=0,j=funcs_multi[name].length;i<j;i++){
				if ( ret || force_process ) {
					// console.log('funcs_multi[name][i]', funcs_multi[name][i]);
					ret = funcs_multi[name][i].call(this, a, b, c, d, e, f);
					// console.log('funcs_multi[name][i] done');
				}
			}
		}
		return ret;
	};

	this.getHref = function(el) {
		if (!el) {
			return undefined;
		}

		if (el.getAttribute && typeof el.getAttribute('xlink:href') === 'string') {
			return el.getAttribute('xlink:href');
		}

		if (typeof el.href === 'string') {
			return el.href;
		}

		return undefined;
	};

	this.onLinkClicked = function(evt) {
		var el = evt.target;
		while (el && !this.getHref(el)) {
			el = el.parentNode;
		}

		var url = $(el).attr('href');

		console.log('link clicked url : ', url, el, evt.target);
		
		if ( this.processStep('onLinkClicked', evt, el, url) ) {
			evt.preventDefault();
			this.goTo( url );
		}
	};

	this.actionfrombrowser = false;
	this.comingfromgoto = false;
	
	this.goTo = function(url) {
		this.comingfromgoto = true;
		if ( this.processStep('onGoTo', url) ) {
			History.pushState(null, null, url);
			this.onStateChange();
		}
	};

	this.onStateChange = function() {
		// alert('state changed')
		this.actionfrombrowser = !this.comingfromgoto;
		this.comingfromgoto = false;
		console.log('can_use_history', can_use_history);
		if (can_use_history) {
			this.ajaxLoad( window.location.toString() );
		}
		$(window).trigger('cymasmooth:statechanged'); // to remove ???
	};

	this.ajaxLoad = function( url ){
		if ( this.processStep('onAjaxLoad', url) ) {
			$.ajax({url: url, context:{url: url}, success: this.ajaxSuccessCB( url )});
		}
	};

	this.ajaxSuccessCB = function(url){
		return function( data ) {
			this.ajaxSuccess( data, url );
		}.bind( this );
	};

	this.ajaxSuccess = function(data, url){
		
		var html = document.createElement( 'div' );
		html.innerHTML = data;

		this.registerPage(url, html);
		this.renderPage(url);
	};

	this.initCurrentPage = function(){
		var url = window.location.toString();
		can_use_history = true;

		this.registerPage(url, $('html').get(0));
		this.renderPage(url, false);
	};

	this.initCurrentPageSimple = function(){
		var url = window.location.toString();
		can_use_history = true;

		this.registerPage(url, $('html').get(0));
		this.updatePages(url);
	};

	this.registerPage = function(url, html){
		var final_el = $(html).find(this.contentQuery).get(0);

		var datas = {
			url: url,
			html: html,
			el: final_el
		};

		if ( this.processStep('onRegisterPage', datas) ) {
			console.log('datas end', datas);
			pages[datas.url] = {
				'model': $(datas.el).data('model'),
				'url': datas.url,
				'uid': $(datas.el).data('uid'),
				'div': datas.el,
				'title': $(datas.html).find('title').html(),
				'html': datas.html
			};
		}

	};

	this.updatePages = function(url){
		if ( this.processStep('onUpdatePages', url) ) {
			this.prev_page = this.next_page;
			this.next_page = pages[url];
		}
	};

	this.renderPage = function(url, record_visit, undefined){

		this.updatePages(url);
		if ( this.processStep('onRenderPage', url, record_visit, undefined) ) {
			this.firstPass = false;
			this.pagesViewed++;
			// funcs.onStart(funcs.getHideAnim);
			funcs.onStart.call(this, funcs.getHideAnim);
		}

	};

	funcs.onStart = function(callback){callback.call(this);};
	
	funcs.getHideAnimnewdeconne = function(){
		var anim = new SkSmooth2CSS3Animator();
		anim.setOnComplete( function(){
			funcs.onHalf(funcs.getShowAnim);
		} );
		
		var anim_datas = getAnim( that.prev_page.model, 'hide' );

		anim_datas.call(that, that.next_page, that.prev_page);

		anim.fullAdd(anim_datas);

		that.animatorObj = anim;
		funcs.onHide(that.animatorObj.play);
	};

	funcs.getHideAnim = function(){
		var anim = new SkSmooth2CSS3Animator();
		anim.setOnComplete( function(){
			funcs.onHalf.call(this, funcs.getShowAnim.bind(this));
		}.bind(this) );
		
		var anim_datas = [];
		// try {
			if ( Anims[this.prev_page.model+'_hide'] ) {
				anim_datas = Anims[this.prev_page.model+'_hide'](this.next_page, this.prev_page);
			}
			else {
				anim_datas = Anims['hide'](this.next_page, this.prev_page);
			}
		// } catch(e){}

		anim.fullAdd(anim_datas);

		this.animatorObj = anim;
		funcs.onHide(this.animatorObj.play);
	};

	funcs.onHide = function(callback){
		callback();
	};
	
	funcs.onHalf = function(callback){callback();};

	funcs.getShowAnim = function(){
		var is_mobile = /ipad|iphone|ipod|android|blackberry|windows phone|opera mini|silk/i.test(navigator.userAgent);
		var anim = new SkSmooth2CSS3Animator();
		anim.setOnComplete( funcs.onEnd.bind(this) );
		var anim_datas = false;

		if ( is_mobile && Anims[this.next_page.model+'_show_mobile'] ) {
			// anim_datas = Anims[this.next_page.model+'_show'](this.next_page, this.prev_page);
			anim_datas = Anims[this.next_page.model+'_show_mobile'].call(this, this.next_page, this.prev_page);
		}
		else if ( Anims[this.next_page.model+'_show'] ) {
			// anim_datas = Anims[this.next_page.model+'_show'](this.next_page, this.prev_page);
			anim_datas = Anims[this.next_page.model+'_show'].call(this, this.next_page, this.prev_page);
		}
		else {
			// anim_datas = Anims['show'](this.next_page, this.prev_page);
			anim_datas = Anims['show'].call(this, this.next_page, this.prev_page);
		}

		anim.fullAdd(anim_datas);
		
		this.animatorObj = anim;
		// funcs.onShow.call(this, this.next_page.div, this.animatorObj.play);
		funcs.onShow.call(this, this.next_page.div, this.onShowLast.bind(this));
		
	};
	/**
	* Call before playing Show animation which enable play
	* 
	* @Method onShow
	* @param callback {Method} SkSmooth.onShowCB
	*/
	funcs.onShow = function(next_page, callback){
		callback();
	};
	this.onShowLast = function(){
		if ( this.processStep('onShowLast') ) {
			this.animatorObj.play();
		}
	};
	/**
	* Call after Show animation
	* 
	* @Method onEnd
	* @param callback {Method} SkSmooth.onEndCB
	*/
	funcs.onEnd = function(){
		$(window).trigger('cymasmooth:onEnd');
	};

	window.addEventListener( 'popstate', this.onStateChange.bind(this) );
	$( document ).on( 'click', 'a', this.onLinkClicked.bind(this) );
};

/**
* Animator class working with css3 anims  	
* which support onComplete callback  	
*
* animation array structure :
* 
* 	[0] => element to animate
* 	[1] => properties to animate
* 	[2] => duration
* 	[3] => callback method
* 	[4] => transition (String)
* 	[5] => delay || 0
*
* timing in milliseconds
* 
* @class SkSmooth.Animator
*/
var _raf = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          window.oRequestAnimationFrame      ||
          window.msRequestAnimationFrame     ||
          function(/* function */ callback, /* DOMElement */ element){
              window.setTimeout(callback, 1000 / 60);
          };
})();


var SkSmooth2CSS3Animator = function(){
	var anim = false;
	var onComplete = null;
	var animsLoaded = 0;
	// var that = this;
	this.fullAdd = function(tl){
		anim = tl;
	};
	this.setOnComplete = function(func){
		onComplete = func;
	};
	this.play = function(){
		// console.log('anim', anim);
		if ( anim ) {
			anim.add(onComplete, '+=0');
			_raf(function(){
				anim.play();
			});
		}
		else {
			onComplete();
		}
	};
	this.stopAndComplete = function(){
		// console.log('anim', anim);
		if ( anim ) {
			anim.time( anim.duration() );
		}
	};
};

// return new SkSmooth2();
return SkSmooth2;

}));


// mySingleton.echoName();
// mySingleton.echoName();
// mySingleton.echoName();