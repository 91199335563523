var $ = require('jquery');
var reveals = require('./../0reveals');

reveals('proj-nav', function(el, tl){
	
	tl.from( $(el).find('.proj__nav__prev'), 1, {
		x: 100,
		opacity: 0,
		ease: Power2.easeOut
	}, 0 )
	
	tl.from( $(el).find('.proj__nav__next'), 1, {
		x: -100,
		opacity: 0,
		ease: Power2.easeOut
	}, 0.1 )
	return tl;
});
