/*! atomic v1.0.0 | (c) 2015 @toddmotto | github.com/toddmotto/atomic */
(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $) {

	var css_em_ratio = 0.2; // used in css to fit in em : XXXXpx = (XXXX * ratio) em;
	
	function e(a, b, minw, maxw, minh, maxh, forcemaxw, forcemaxh) {
		var min = minw / minh;
		var max = maxw / maxh;
		var c = a / b,
			d, e, m;
		switch (!0) {
			case min > c:
				e = a;
				m = Math.round(a / min);
				// if ( m > forcemaxh ) {
				// 	m = forcemaxh;
				// }
				d = Math.round(.5 * (b - m));
				c = 0;
				break;
			case max < c:
				m = b;
				e = Math.round(max * b);
				// if ( e > forcemaxw ) {
				// 	e = forcemaxw;
				// }
				c = Math.round(.5 * (a - e));
				d = 0;
				break;
			default:
				e = a,
					m = b,
					d = c = 0
		}
		return {
			x: c,
			y: d,
			width: e,
			height: m
		}
	}

	var resizeb = function($els, only_font_size) {
		var args = [];
		for(var i=0,j=arguments.length;i<j;i++){
			args.push( arguments[i] );
		}
		$els.each( function( i, el ){
			if ( args.length < 4 ) {
				resize( el, only_font_size );
			}
			else {
				resize( el, only_font_size, args[2], args[3] );
			}
		} );
	};

	var resize = function(el, only_font_size) {
		if ( $(el).data('init') == 1 ) {
			// return;
		}

		// if ( window.matchMedia("only screen and (max-width: 920px)").matches ) {
		// 	$(el).css({
		// 		width: '100%',
		// 		height: '100%',
		// 		top: 0,
		// 		left: 0
		// 	});
		// 	return;
		// }

		$(el).data('init', 1);

		var design_height = parseInt($(el).attr('data-js-design-height'), 10);
		var font_size_ratio = design_height * css_em_ratio;

		var minw = parseInt($(el).attr('data-js-minw'), 10);
		var minh = parseInt($(el).attr('data-js-minh'), 10);

		var maxw = parseInt($(el).attr('data-js-maxw'), 10);
		var maxh = parseInt($(el).attr('data-js-maxh'), 10);
		var forcemaxw = parseInt($(el).attr('data-js-force-maxw'), 10);
		var forcemaxh = parseInt($(el).attr('data-js-force-maxh'), 10);
		// alert(minw)
		var tww = 0;
		var twh = 0;
		if ( arguments.length == 4 ) {
			tww = arguments[2];
			twh = arguments[3];
		}
		else {
			tww = $(window).width();
			twh = $(window).height();
		}

		var max_top = 9999;
		if ( $(el).attr('data-js-em-stack-top') == '1' ) {
			max_top = 0;
		}

		// var tww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		// var twh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

		// console.log('tww', tww);
		// console.log('twh', twh);

		// console.log('ww', ww);
		// console.log('wh', wh);
		
		var h = e(tww, twh, minw, maxw, minh, maxh, forcemaxw, forcemaxh);
		var fs = h.height / font_size_ratio;
		if ( only_font_size === true ) {
			$(el).css({
				"font-size": fs + "px"
			});
		}
		else {
			
			$(el).css({
				"font-size": fs + "px",
				width: h.width + "px",
				height: h.height + "px",
				top: Math.min(max_top, h.y) + "px ",
				left: h.x + "px "
			});
		}
	};

	return {
		resize: resize,
		resizeb: resizeb
	};

}));