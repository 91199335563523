var $ = require('jquery');

module.exports = function(url, record_visit, undefined){ // onRenderPage, url, record_visit, undefined
	var html = $(this.next_page.html);
	// update head menu class
	var index = html.find('.head__menu__item__link.is-current').parent().index();
	$('.head__menu__item__link').removeClass('is-current');
	if ( index >= 0 )  {
		$('.head__menu__item').eq( index ).find('.head__menu__item__link').addClass('is-current');
	}
};