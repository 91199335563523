var $ = require('jquery');
var revealManager = require('./0reveals-manager.js');

var canReveal = false;
var scrollTop = false;
var previousScrollTop = false;
var windowWidth = false;
var windowHeight = false;
var maxScrollTop = false;
var head = $('.head');
var scrollHandler = function() {
    scrollTop = $(window).scrollTop();
    // if (scrollTop > previousScrollTop && scrollTop > 50) {
    //     head.addClass('is-scrolling-down');
    // } else {
    //     head.removeClass('is-scrolling-down');
    // }
    if (scrollTop > 50) {
        head.addClass('is-scrolling-down');
    } else {
        head.removeClass('is-scrolling-down');
    }
    scrollRevealTrigger(!1);
    $('.is-desktop .parallaxd').each(function() {
        var container = $(this);
        var c_offset = container.offset();
        var c_height = container.outerHeight();
        c_offset.top = c_offset.top - c_height;
        var total = windowHeight + scrollTop;
        var totalb = c_offset.top + c_height + c_height;
        // console.log('paralx infos', c_offset.top, c_height, scrollTop, windowHeight, maxScrollTop, total, totalb);
        if ( total > c_offset.top && scrollTop < totalb ) {
            // console.log('do');
            var pc = ( total - c_offset.top ) / ( windowHeight + c_height + c_height );
            pc = pc - 0.5;
            pc = pc * 2;
            var ratio = $(this).attr('data-parallax-speed') || 1;
            ratio = parseFloat(ratio);
            pc = pc * ratio;
            container.css({
                transform: 'translate3d(0, ' + (pc * -100) + '%, 0)',
                opacity: 1
            })
            // console.log('pc', pc);
        }
        // var title = container.find('.title');
        // var target = container.siblings('.page-header, .scroller-background').last();
        // if (container.offset().top + container.height() / 2 > target.offset().top + target.outerHeight()) {
        //     container.addClass('page-scroller--blue')
        // } else {
        //     container.removeClass('page-scroller--blue')
        // }
        // var y = scrollTop / maxScrollTop * 100;
        // title.css({
        //     transform: 'translate3d(0, ' + y + '%, 0)'
        // })
    });
    $('.is-desktop .parallaxb').each(function(i, el) {
        if (canReveal) {
            var wh = $(window).height();
            if ( scrollTop < wh ) {
                var pc = scrollTop / wh;
                $(el).css({
                    transform: 'scaleY('+pc+')'
                    // opacity: 1
                })
            }
            // var c_offset = $(el).offset();
            // var c_height = $(el).outerHeight();
            // // if ( scrollTop > c_offset.top && scrollTop < c_offset.top + c_height ) {
            // if ( scrollTop < c_offset.top + c_height ) {
            //     // console.log('scrollTop', scrollTop, c_offset, c_height);
            //     var pc = ( scrollTop - c_offset.top ) / c_height;
            //     pc = Math.min( 1, Math.max( 0, pc ) );
            //     // console.log('pc', pc);
            //     $(el).css({
            //         transform: 'translate3d(0, ' + (pc * 75) + '%, 0)'
            //         // opacity: 1
            //     })
            // }
        }
    });
    $('.is-desktop .parallaxc').each(function(i, el) {
        if (canReveal) {
            var c_offset = $(el).offset();
            var c_height = $(el).outerHeight();
            // if ( scrollTop > c_offset.top && scrollTop < c_offset.top + c_height ) {
            if ( scrollTop < c_offset.top + c_height ) {
                // console.log('scrollTop', scrollTop, c_offset, c_height);
                var pc = ( scrollTop - c_offset.top ) / c_height;
                pc = Math.min( 1, Math.max( 0, pc ) );
                // console.log('pc', pc);
                $(el).css({
                    transform: 'translate3d(0, ' + (pc * 75) + '%, 0)'
                    // opacity: 1
                })
            }
        }
    });
    previousScrollTop = scrollTop;
};

var scrollRevealTrigger = function(intro) {
    if (canReveal) {
        var limit = 0.85;
        if (intro)
            limit = 1;
        limit = 0.75;
        limit = 0.9;
        var revealLimit = windowHeight * limit + scrollTop;
        var toReveal = [];
        // $('.scroll-reveal').not('.is-revealed, .to-reveal').each(function() {
        $('[data-reveal]').not('.is-revealed, .to-reveal').each(function() {
            var element = $(this);
            var top = element.offset().top - Number(element.css('marginTop').replace('px', ''));
            var bottom = top + element.outerHeight();
            // console.log('infos', this, top, revealLimit, scrollTop, maxScrollTop);
            if (revealLimit >= top || scrollTop >= maxScrollTop - 10) {
                if (top <= scrollTop + windowHeight && bottom >= scrollTop) {
                    toReveal.push(element);
                }
                else {
                    // element.addClass('is-revealed is-revealed-directly')
                }
            }
        });
        var delay = 0;
        var mtl = new TimelineMax({
            paused : true,
            onComplete: function(){
            }
        });
        toReveal.forEach(function(element, index) {
            delay += 0.200;
            mtl.call(function() {
                element.addClass('is-revealed');
                element.removeClass('to-reveal');
                var tl = new TimelineMax({
                    paused : true,
                    onComplete: function(){}
                });
                var bloc_tl = revealManager( element.attr('data-reveal'), element, tl );
                element.tl = bloc_tl;
                bloc_tl.play();
            }, null, null, delay);
            element.addClass('to-reveal');
        })
        mtl.play();
    }
};

var resizeHandler = function() {
    windowWidth = $(window).width();
    if (windowHeight != window.innerHeight) {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
        var vw = $('body').width() * 0.01;
        document.documentElement.style.setProperty('--vw', vw + 'px');
        windowHeight = window.innerHeight
    }
    maxScrollTop = $(document).height() - windowHeight;
    scrollHandler()
};

var stopParalax = function(){
    canReveal = false;
};

$(window).on('resize orientationchange', resizeHandler);
$(window).on('scroll', scrollHandler);
$(window).on('paralax:play', resizeHandler);
$(window).on('cymasmooth:statechanged', stopParalax);

module.exports = function(to_set) {
    canReveal = to_set;
    if ( to_set === true ) {
        resizeHandler();
    }
};

