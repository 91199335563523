(function(window, factory) {
	'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery'),
			require('fdb')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, fdb) {

	var Plugin = function(container) {
		// console.log('isotope_layout', isotope_layout);
		var
			$window = $(window),
			// $wrapper = $container.find('[data-js="frise"]'),
			$wrapper = $(container),
			isSlideVisible = false;

		var close = function(){
			if ( video ) {
				video.pause();
			}
			// dont_ckeck_menu === true = go to home otherwise go to main menu
			// if go to main menu, only hide right controls and frame by fade out
			// otherwise set scale fade out anim to popup
			$wrapper.addClass('is-hiding');
			setTimeout(function() {
				$wrapper.removeClass('is-preparing is-showing is-hiding is-showing-instant is-preparing-op is-hiding-op');
			}, 700);
		};

		// alert('cc')
		var popupVideoEnded = function() {
			// var video = $('[data-js="main-popup-contenwt"]').find('video').get(0);
			// cymavideo.exitFullscreen();
			close();
		};

		// var inner = '';
		// if ( $('[data-is-webapp="1"]').size() === 0 ) {
		// 	inner = '<source src="'+$wrapper.find('[data-js-video-url]').attr('data-js-video-url')+'" type="video/mp4" />';
		// }

		// $wrapper.find('[data-js="popup-video-content"]').html('<video class="popup__video" controls="controls" autoplay="autoplay" width="1600" height="800">'+inner+'</video>');
		var video = false;


		// video.addEventListener('ended', popupVideoEnded, false);

		$wrapper.find('[data-js="close-popup"]').click(close);

		// var setVideoSourceFromBuffer = function( buffer ) {
		// 	if ( buffer ) {
		// 		// alert('from cache') 
		// 		var blob = new Blob( [buffer], {type: 'video/mp4'} );
		// 		video.src = URL.createObjectURL( blob );
		// 	}
		// 	else {
		// 		video.innerHTML = '<source src="' + $wrapper.find( '[data-js-video-url]' ).attr( 'data-js-video-url' ) + '" type="video/mp4" />';
		// 	}
		// 	startNext();
		// };

		var startMuted = function(src){
			start( src, true )
		};

		var is_init = false;
		var start = function( src, muted ){
			var muted_att = '  ';
			if ( muted === true ) {
				muted_att = ' muted="muted" ';
			}
			var inner = '<source src="'+src+'" type="video/mp4" />';
			$wrapper.find('[data-js="popup-video-content"]').html('<video class="popup__video" controls="controls" '+muted_att+' playsinline="playsinline">'+inner+'</video>');
			video = $wrapper.find('video').get(0);
			video.addEventListener('ended', popupVideoEnded, false);
			// var video_src = $(evt.currentTarget);
			// video_src = video_src.attr('data-src');
			// console.log('video_src', video_src);
			// $('body').removeClass( 'is-main-menu-open' );
			// if ( $('[data-is-webapp="1"]').size() > 0 && is_init === false ) {
			// 	// get cache value
			// 	fdb.get('videodatab', setVideoSourceFromBuffer);
			// 	is_init = true;
			// }
			// else {
				setTimeout(function() {
					startNext();
				}, 100);
			// }
		};
		var elementClicked = function(evt){
			var video_src = $(evt.currentTarget);
			video_src = video_src.attr('data-src');
			console.log('video_src', video_src);
			start(video_src);
			// $('body').removeClass( 'is-main-menu-open' );
			// if ( $('[data-is-webapp="1"]').size() > 0 && is_init === false ) {
			// 	// get cache value
			// 	fdb.get('videodatab', setVideoSourceFromBuffer);
			// 	is_init = true;
			// }
			// else {
			// 	startNext();
			// }
		};

		
		var startNext = function(){
			video.currentTime = 0;
			video.play();
			
			$wrapper.addClass('is-preparing');
			$wrapper.get(0).offsetWidth;
			$wrapper.addClass('is-showing');
		};

		var popup_id = $wrapper.attr('data-js-popup-id');

		$( document ).on('click', '[data-js-open-popup="'+popup_id+'"]', elementClicked);
		// $('[data-js-open-popup="'+popup_id+'"]').on('click', start);

		return {
			start: startMuted
		};

	};

	// =======================
	$.fn['popupvideo'] = function(option, args) {
		return this.each(function() {
			var name = 'plugin_' + 'popupvideo';
			var data = $.data(this, name);
			var opts = typeof option === "object" && option;
			if (!data) {
				// alert('tta')
				$.data(this, name, (data = Plugin(this, opts)));
				// alert('ttb')
				console.log('data', data);
			}
			if (typeof option === "string") {
				data[option](args);
			}
		});
	};

	$(function(){
		$('[data-js-popup-id="popup-video"]').each(function(i, el){
			$(el).popupvideo();
		});
	});

}));
