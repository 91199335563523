// https://github.com/bradvin/social-share-urls
var $ = require('jquery');
var popup = require('mypop'); 


var rs_urls = {
	'fb' : 'https://www.facebook.com/sharer/sharer.php?u=%URL%',
	'tw' : 'https://twitter.com/intent/tweet?text=%TITLE%&url=%URL%',
	'li' : 'https://www.linkedin.com/shareArticle?mini=true&url=%URL%&title=%TITLE%',
	'we' : 'http://service.weibo.com/share/share.php?url=%URL%&appkey=&title=%TITLE%&pic=%PIC%&ralateUid='
};

$( document ).on('click', '[data-share]', function(evt) {
	var rs = $(this).attr('data-share');
	var url = $(this).attr('data-share-url') || window.location.toString();
	var title = $(this).attr('data-share-title') || $(document).find("title").text();
	var popup_w = parseInt( $(this).attr('data-share-popup-w'), 10 ) || 580;
	var popup_h = parseInt( $(this).attr('data-share-popup-h'), 10 ) || 470;
	// weibo img
	var img = '';
	var img_att = $(this).attr('data-share-pic') || '';
	if ( img_att.length ) {
		var root = url.split('/');
		root = root[0] + '//' + root[2] + '/';
		img = root + $(this).attr('data-share-pic') || '';
	}
	// var url = window.location.toString();
	// var url = encodeURI(window.location.toString());
	// var title = encodeURI($(document).find("title").text());
	// var titleb = $(document).find("title").text();
	var href = rs_urls[rs];
	href = href.replace( '%URL%', encodeURI(url) );
	href = href.replace( '%TITLE%', encodeURI(title) );
	href = href.replace( '%PIC%', encodeURI(img) );
	console.log('href', href);
	popup( href, title, popup_w, popup_h );
});

