var $ = require('jquery');
var v1 = require('cymasmooth-v1');
var v2 = require('cymasmooth-v2');

var out = false;

if ( $('html').attr('data-cymasmooth') == 'v2' ) {
	console.log('use cymasmooth v2');
	out = new v2();
}
else {
	console.log('use cymasmooth v1');
	out = new v1();
	console.log('tt', out);
}


module.exports = out;