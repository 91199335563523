var $ = require('jquery');

var createFakeSelect = function(el, elb, undefined){
	if ( elb !== undefined ) { 
		// needed to fix events call with el as second argument
		el = elb;
	}
	$(el).find('select').not('.is-customized').each(function(i, el){
		var wrap = $( '<div class="fake__select"></div>' ).prependTo( $(el).parent() );
		var display = $( '<div class="select__display"><span class="select__display__label"></span></div>' ).appendTo( wrap );
		var choose = $( '<div class="select__choose"></div>' ).appendTo( wrap );
		$(el).find('option').each(function(ii, opt){
			if ( ii == 0 ) {
				display.find('span').text( opt.text );
			}
			if ( ii > 0 ) {
				var o = $('<div class="select__choose__item">'+opt.text+'</div>').appendTo( choose );
				o.data('val', opt.value);
				o.data('txt', opt.text);
			}
		});
		// $( el ).hide();
		display.on('click', function(){
			choose.toggleClass('is-visible');
		});
		choose.find('div').on('click', function(){
			el.value = $(this).data('val');
			choose.removeClass('is-visible');
			display.find('span').text( $(this).data('txt') );
			choose.find('div').removeClass('is-selected');
			$(this).addClass('is-selected');
		});
		$(el).addClass('is-customized');
	});
};

$(window).on('site:initPage', createFakeSelect);

module.exports = createFakeSelect;
