(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		// require('lightslide.js');
		// require('./ratpmenu.js');

		// require('./formlogin.js');
		// require('./axo.js');
		// require('./revideo.js');
		// require('./brochure.js');
		// require('./homepicto.js');

		// require('formcheckajax');

		// require('./wheelnav.js');
		
		// require('./homeslideshow.js');
		// require('./filtersgrid.js');
		// require('./prodvisus.js');
		// require('./blocctavoir.js');
		// require('./medias.js');
		// require('./igcforms.js');
		// require('./filterstacked.js');
		
		module.exports = factory(
			window,
			require('jquery'),
			require('cymasmooth'),
			require('./homeslidechange.js'),
			require('fdb'),
			require('fetcho'),
			require('./form-select.js'),
			require('./formgeneric.js'),
			require('./anims/paralax.js')
			// require('./show360.js')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, cymasmooth, homeslidechange, fdb, fetcho, formSelect, formgeneric, paralax) {

	var site = {
		// isTransitioning: false
		cymaSmooth: true,
		hasCachedVideo: false
	};

	site.initPage = function( el ) {

		setTimeout(function() {
			paralax( true );
		}, 200);

		$(window).trigger('site:initPage', el);

		$(el).find('[data-js="frise-animation"]').friseanimation({
			innerQuery: '.bloc__frise__logo__items__inner',
			wrapperQuery: '.bloc__frise__logo__items',
			itemQuery: '.bloc__frise__logo__item'
		});

		$(el).projetshover();

		// formSelect( el );
		// $(el).find('[data-js="formcontact"]').each(function(i, eel){
		// 	formgeneric( eel, function(data){
		// 		return $(data).find('.contactreturn').size() > 0;
		// 	}, function(){
		// 		$('.formcheck').removeClass('is-loading').addClass('is-disabled');
		// 		return false; // set can_submit_form to false
		// 	} );
		// });
		// $(el).find('[data-js="formlogin"]').each(function(i, eel){
		// 	formgeneric( eel, function(data){
		// 		return $(data).find('.dls__items').size() > 0;
		// 	}, function( $form ){
		// 		cymasmooth.goTo( $form.attr('action') );
		// 		return false; // set can_submit_form to false
		// 	} );
		// });

		// $(el).find('.bloc__voir__cta').blocctavoir();


	};

	return site;

}));

