(function(window, factory) {
	'use strict';
	if (typeof exports !== 'undefined') {
		require('slick');
		module.exports = factory(
			window,
			require('jquery')
			// require('isotope-layout')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $) {

	var animBgSlideshowComplete = function(container, classname, prev, next) {
		$(container).removeClass(classname);
		// $(prev).hide().css('z-index', 2);
		$(prev).css('z-index', 2);
		// $(prev).css('visibility', 'hidden');
		$(prev).css('display', 'none');
		$(prev).removeClass('is-active');
		$(next).css('z-index', 3);
		$(next).addClass('is-active');
	};

	var a = function($container, prev_item, cur_item, options, $items, datas, way) {
		var ww = $(window).width();
		var wh = $(window).height();
		var $prev = $items.get(prev_item);
		var $next = $items.get(cur_item);
		var origw = (way < 0) ? 0 : ww;
		var is_mobile = /ipad|iphone|ipod|android|blackberry|windows phone|opera mini|silk/i.test(navigator.userAgent);
		is_mobile = true;
		var tl = new TimelineMax({
			paused: true,
			onComplete: animBgSlideshowComplete,
			onCompleteParams: [$container.get(0), options.cantAnimClass, $prev, $next]
		})
		// .set($next, {zIndex:4,display:'block'})
		// .set($next, {zIndex:4,visibility:'visible'})
		.set($next, {zIndex:4,display:'block',visibility:'visible'})
		if ( is_mobile ) {
			tl.fromTo($next, 1, {
				opacity: 0.001,
				force3D: 'auto',
				x: 100*way
			},{
				opacity: 1,
				force3D: 'auto',
				x: 0,
				ease: Power3.easeInOut
			}, '0');
		}
		else {

			tl.fromTo($next, 1, {
				force3D: 'auto',
				// clip:'rect(0px '+origw+'px 2000px '+origw+'px)',
				x: 100*way
			},{
				force3D: 'auto',
				// clip:'rect(0px '+ww+'px 2000px 0px)',
				x: 0,
				ease: Power3.easeInOut
			}, '0');
		}
		tl.fromTo($prev, 1, {
			force3D: 'auto',
			x: 0
		}, {
			force3D: 'auto',
			x: -100*way,
			ease: Power3.easeInOut
		}, '0');
		tl.fromTo($prev, 0.8, {
			opacity: 1
		}, {
			opacity: 0.001,
			ease: Power1.easeOut
		}, '0');
		tl.play();
	};

	return a;

}));