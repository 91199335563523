require('./mask.js');
require('gsap18/plugins/DrawSVGPlugin');

var $ = require('jquery');
// var site = require('site');

var showPageFinal = function(){
	// alert('showpage')
	// $('.site__loader__185').remove();
	var tlt = new TimelineMax({
		// paused : true
	});
	tlt.addLabel('mainanimstart');
	// var has_reveal = revealManager( 'homeanim', $('html'), tlt ); // edit to current page model
	var has_reveal = revealManager( $('.page__wrap').attr('data-model') + 'anim', $('html'), tlt, 'mainanimstart' ); // edit to current page model
	// var tl = new TimelineMax({
 //        paused : true,
 //        onComplete: function(){}
 //    });
 //    revealManager( element.attr('data-reveal'), element, tl ).play();
	// if ( $('.home__logo').size() ) {
		
		
	// }
	// else {
	// 	tlt.to( $('.site__loader__185'), 0.75, {
	// 		opacity: 0
	// 	} );
	// }

	tlt.call(function(){
		site.initPage( $('html') );
		// $('.site__loader__185').remove();
	});
};



// create separate anim for the 8

// var tlt_huit = new TimelineMax({
// 	paused : true
// });

// // draw 8 top
// tlt_huit.fromTo( $('#line8top'), 0.8, {
//     drawSVG:"0% 0%",
//     opacity: 1
// }, {
//     drawSVG:"0 45.5%",
//     opacity: 1,
//     // immediateRender:false,
//     ease: Power0.easeOut
// }, 0);


// // draw 8 bottom
// tlt_huit.fromTo( $('#line8bottom'), 0.8, {
//     drawSVG:"45% 45%",
//     opacity: 1
// }, {
//     drawSVG:"45% 100%",
//     opacity: 1,
//     // immediateRender:false,
//     ease: Power0.easeOut
// }, 0.8);

var start_anim = function(cb){

var tlt = new TimelineMax({
	paused : true
});

// tlt.to( $('.site__loader__logo--left, .site__loader__logo--right'), 0.6, {
//     transformOrigin: '50% 50%',
//     scaleY:1,
//     ease: Power2.easeOut
// });

tlt.addLabel( 'start' );
tlt.to( $('.site__loader__logo--left, .site__loader__logo--right'), 1, {
    x:0,
    ease: Power2.easeOut
}, 'start');


tlt.to( $('.site__loader__logo--mid'), 1, {
    opacity:1,
    ease: Power0.easeOut
}, 'start+=0.1');

var step_delay = '0.9';
tlt.fromTo( $('.site__loader__logo--top'), 1, {
    opacity:0,
    y: '30rem'
}, {
    opacity:1,
    y: 0,
    ease: Power2.easeOut
}, 'start+='+step_delay);

tlt.fromTo( $('.site__loader__logo--bot'), 1, {
    opacity:0,
    y: '-30rem'
}, {
    opacity:1,
    y: 0,
    ease: Power2.easeOut
}, 'start+='+step_delay);

tlt.addLabel('next');
tlt.to( $('.site__loader__logo--top'), 1, {
    opacity:0,
    y: '-60rem',
    ease: Power2.easeIn
}, 'next');

tlt.to( $('.site__loader__logo--bot'), 1, {
    opacity:0,
    y: '60rem',
    ease: Power2.easeIn
}, 'next');

tlt.to( $('.site__loader__logo--mid, .site__loader__logo--left, .site__loader__logo--right'), 0, {
    opacity: 0
}, 'next' );
tlt.to( $('.site__loader__logo--cut-top, .site__loader__logo--cut-bot'), 0, {
    opacity: 1
}, 'next' );

tlt.to( $('.site__loader__logo--cut-top'), 0.7, {
    opacity:0,
    y: '-60rem',
    ease: Power2.easeIn
}, 'next+=0.5');

tlt.to( $('.site__loader__logo--cut-bot'), 0.7, {
    opacity:0,
    y: '60rem',
    ease: Power2.easeIn
}, 'next+=0.5');


tlt.to( $('.site__loader__wrap'), 1.1, {
    opacity:0,
    ease: Power0.easeIn
});

// tttl.to($('.site__loader'), 1, {
//     opacity: 0
// })
// tttl.staggerFrom($('.header__logo, .header__baseline, .header__toggler'), 1, {
//     opacity: 0,
//     y: -30,
//     ease: Power3.easeOut
// }, 0.15, '-=0.5')
// tttl.call(function(){
//     site.initPage( $('html') );
//     $('.site__loader').remove();
// }, null, null, '-=0.55')

tlt.staggerFrom($('.head__not__menu .col__33'), 1, {
    opacity: 0,
    y: -30,
    ease: Power3.easeOut
}, 0.15, '-=0.5');


tlt.call(function(){
    // console.log('next step anim');
    // showPageFinal();
    // site.initPage( $('html') );
    cb();
    // $('.site__loader__wrap').remove();
// }, false, false, 1);
}, false, false, '-=0.55');

tlt.call(function(){
    // console.log('next step anim');
    // showPageFinal();
    // site.initPage( $('html') );
    // cb();
    $('.site__loader__wrap').remove();
// }, false, false, 1);
}, false, false, '+=0.05');


setTimeout(function() {
	tlt.timeScale(1);
	tlt.play();
}, 500);

};

// window.onload = start_anim;

module.exports = start_anim;

// start();