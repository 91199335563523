var $ = require('jquery');
// alert('hop')
$.fn.masksimple = function() {
	var out = [];
	$(this).each(function(i, el){
		var t = $('<div class="jq__mask__img" />').appendTo(el);
		// t.append(el);
		out.push( t );
	});

	return $(out);
};

$.fn.mask = function() {
	var out = [];
	$(this).each(function(i, el){
		var t = $('<div class="jq__mask" />').insertBefore(el);
		t.append(el);
		out.push( el );
	});

	return $(out);
};

$.fn.maskover = function() {
	var out = [];
	$(this).each(function(i, el){
		var t = $('<div class="jq__mask__over" />').appendTo(el);
		$(el).addClass('jq__mask__over__wrap');
		// t.append(el);
		out.push( t );
	});

	return $(out);
};