var anims = {};

var return_false = function(){
	return false;
};

module.exports = function(a, b, undefined) {
    // console.log('reveals', a, b, anims);
    if ( b === undefined ) {
        return anims[a] || return_false;
    }
    else {
        anims[a] = b;
    }
};