var $ = require('jquery');

var Plugin = function (el) {
	this.el = el;
	this.current_index_el = 0;
	this.current_index_src = 0;
	this.pause_duration = parseInt( $(el).attr('data-shuffler-pause-duration'), 10 );
	this.anim_speed = parseInt( $(el).attr('data-shuffler-anim-speed'), 10 ) * 0.001;
	// this.pause_duration += this.anim_speed * 1000;
	this.figures = $(el).find('li').not('.is-hidden').find('figure');
	this.max_imgs = this.figures.length;
	this.to_show = [];
	$(el).find('li.is-hidden img').each(function(i, img){
		this.to_show.push(img.src);
	}.bind(this));
	// console.log('this.to_show', this.to_show);
	// console.log('this.max_imgs', this.max_imgs);
	this.change_order = [];
	for(var i=0,j=this.max_imgs;i<j;i++){
		this.change_order.push(i);
	}
	var shuffle = function(a) {
	    var j, x, i;
	    for (i = a.length - 1; i > 0; i--) {
	        j = Math.floor(Math.random() * (i + 1));
	        x = a[i];
	        a[i] = a[j];
	        a[j] = x;
	    }
	    return a;
	};
	this.change_order = shuffle( this.change_order );
	// console.log('this.change_order', this.change_order);
	var imgs = $(el).find('li').not('.is-hidden').find('img').get();
	for(var i=0,j=this.change_order.length;i<j;i++){
		this.to_show.push( imgs[i].src );
	}
	this.to_show_imgs = [];
	for(var i=0,j=this.to_show.length;i<j;i++){
		var src = this.to_show[i];
		var img = new Image();
		img.src = src;
		this.to_show_imgs.push(img);
		
	}
	this.max_src = this.to_show.length;
	// console.log('this.to_show', this.to_show);
	// $(this.el).find('[data-js="open-filters"]').on('click', $.proxy(this.___elClicked, this));
	// this.is_open = $(this.el).hasClass('is-filters-open');
	
};

var proto = Plugin.prototype;

proto.start = function ( evt ) {
	// console.log('start shuffler');
	this.startChange();
};

proto.startChange = function (  ) {
	// console.log('startChange shuffler');
	setTimeout(function() {
		this.changeImage();
		this.startChange();
	// }.bind( this ), 1000);
	}.bind( this ), this.pause_duration);
};

proto.changeImage = function ( evt ) {
	// console.log('changeImage');
	this.createChangeAnim();


	// console.log('this.current_index_el', this.current_index_el);
	this.current_index_el = ( this.current_index_el + 1 ) % this.max_imgs;
	// console.log('this.current_index_el', this.current_index_el);


	// console.log('this.current_index_src', this.current_index_src);
	this.current_index_src = ( this.current_index_src + 1 ) % this.max_src;
	// console.log('this.current_index_src', this.current_index_src);
};

proto.createChangeAnim = function ( evt ) {
	// console.log('createChangeAnim');
	// create image element inside element to change with current index src
	// var img = $('<img class="shuffler__new__img" src="'+this.to_show[this.current_index_src]+'" />');
	var img = $(this.to_show_imgs[this.current_index_src]).clone().addClass('shuffler__new__img');
	// console.log('this.to_show[this.current_index_src]', this.to_show[this.current_index_src]);
	var fig = this.figures[ this.change_order [ this.current_index_el ] ];
	var old_img = $(fig).find('img');
	img.appendTo(fig);

	var tl = new TimelineMax({
	    paused : true,
	    onComplete: function(){}
	});

	tl.to(img, this.anim_speed, {
		y: '0%'
	}, 0);

	tl.to(old_img, this.anim_speed, {
		y: '-100%'
	}, 0);

	tl.call(function(){
		img.css({position:'relative'})
		old_img.remove()
	})

	setTimeout(function() {
		tl.play();
	}, 20);

};

var initPage = function(evt, el){

	$(el).find('[data-js="shuffler"]').each(function(i, el){
		el['shuffler'] = new Plugin(el);
	});

};

$(window).on('site:initPage', initPage);