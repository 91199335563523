var $ = require('jquery');

module.exports = function(evt, el, url){
	if ( url == History.getState().url && $('[data-is-webapp="1"]').size() === 0 ) {
		// prevent link to make his default action
		evt.preventDefault();
		// stop here
		return false;
	}
	return true;
};