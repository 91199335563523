var $ = require('jquery');

var Plugin = function (el) {
	this.el = el;
	$(this.el).find('[data-js="open-filters"]').on('click', $.proxy(this.___elClicked, this));
	this.is_open = $(this.el).hasClass('is-filters-open');
	
};

var proto = Plugin.prototype;

proto.___elClicked = function ( evt ) {
	// console.log('this.open a', this.open);
	if ( !this.open ) {
		var tl = new TimelineMax({
		    paused : true,
		    onComplete: function(){}
		});
		var lis = $(this.el).find('li').get().reverse();
		tl.staggerFromTo( lis, 0.5, {
			opacity: 0,
			ease: Power0.easeOut
		}, {
			opacity: 1,
			ease: Power0.easeOut
		}, 0.025, 0 );
		tl.staggerFromTo( lis, 0.5, {
			// x: '100',
			cycle: {
				x: function(i) {
					return Math.random() * 200 - 100
					return Math.random() * 50
				},
				y: function(i) {
					return Math.random() * 200 - 100
					return Math.random() * 50
				}
			}
		}, {
			x: 0,
			y: 0
		}, 0.025, 0 );
		$(this.el).addClass('is-filters-open');
		tl.play()
	}
	else {
		var tl = new TimelineMax({
		    paused : true,
		    onComplete: function(){}
		});
		var lis = $(this.el).find('li').get();
		tl.staggerFromTo( lis, 0.5, {
			opacity: 1,
			ease: Power0.easeOut
		}, {
			opacity: 0,
			ease: Power0.easeOut
		}, 0.001, 0 );
		tl.staggerFromTo( lis, 0.5, {
			x: 0,
			y: 0
		}, {
			// x: '100',
			cycle: {
				x: function(i) {
					return 400 - 20 * i
					return Math.random() * 200 - 100
					return Math.random() * 50
				}
			}
		}, 0.001, 0 );
		tl.call(function(){
			$(this.el).removeClass('is-filters-open');
		}, [], this);
		tl.play()
	}
	this.open = !this.open;
	// console.log('this.open a', this.open);
};

var initPage = function(evt, el){

	$(el).find('[data-js="proj-filters"]').each(function(i, el){
		el['jqfuinput'] = new Plugin(el);
	});

};

$(window).on('site:initPage', initPage);