(function(window, factory) {
	'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery'),
			require('cymasmooth')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, cymasmooth) {

	var Plugin = function(container, opts) {
		var inner_query = opts.innerQuery;
		var wrapper_query = opts.wrapperQuery;
		var item_query = opts.itemQuery;
		$(container).find(item_query).clone()
			.appendTo( $(container).find(wrapper_query) )
		var tl = new TimelineMax({repeat:-1});
		tl.fromTo( $(container).find(wrapper_query), 1, {
			x: '-25%'
		}, {
			x: '-75%',
			ease:Power0.easeOut
		});

		var main_spd = $(container).attr('data-frise-spd') || 300;
		main_spd = parseInt( main_spd, 10 );

		var spd = 1;
		var resize = function(){
			var w = $(container).find(wrapper_query).width();
			var h = $(container).find(wrapper_query).height();
			console.log('w', w);
			// speed in px/s = 300px / sec
			// spd = w / 300;
			spd = w / main_spd;
			TweenMax.set(tl, {timeScale:1/spd});
			$(container).find(inner_query).css({height:h});
		};
		$(window).on('resize', resize);
		resize();

		var mouseover = function(){
			TweenMax.to(tl, 1, {timeScale:0.00001});
		};

		var mouseout = function(){
			TweenMax.to(tl, 1, {timeScale:1/spd});
		};

		$(container).find(wrapper_query).hover(mouseover, mouseout);
	};

	// =======================
	$.fn['friseanimation'] = function(option, args) {
		return this.each(function() {
			var name = 'plugin_' + 'friseanimation';
			var data = $.data(this, name);
			var opts = typeof option === "object" && option;
			if (!data) {
				$.data(this, name, (data = Plugin(this, opts)));
			}
			if (typeof option === "string") {
				data[option](args);
			}
		});
	};

}));