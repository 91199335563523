// var $ = require('jquery');
var cymasmooth = require('cymasmooth');

cymasmooth.registerFunc( 'linkClickedForce', require('cymasmooth/link-clicked') );

cymasmooth.registerFuncMulti( 'onLinkClickedBlocking', require('cymasmooth/link-clicked-blocking') );
cymasmooth.registerFuncMulti( 'onLinkClickedBlocking', require('cymasmooth/link-clicked-same-url-do-nothing') );
cymasmooth.registerFuncMulti( 'onLinkClickedDone', require('cymasmooth/link-clicked-done') );

cymasmooth.registerFuncMulti( 'onAjaxLoad', require('cymasmooth/ajax-load-progress') );

require('cymasmooth/freeze-ui'); // onAjaxLoad Event

cymasmooth.registerFuncMulti( 'onRenderPage', require('cymasmooth/update-seo') );
cymasmooth.registerFuncMulti( 'onRenderPage', require('cymasmooth/update-menu') );

cymasmooth.registerFunc( 'onShow', require('cymasmooth/onshow-loading') ); // only one func here

cymasmooth.registerFuncMulti( 'onShowLast', require('cymasmooth/ajax-load-progress-done') );
