var $ = require('jquery');
var reveals = require('./../0reveals');

reveals('imagefromright', function(el, tl){
	$(el).find('.proj__media__visu').addClass('rel jq__mask');
	var img_mask = $('<div class="jq__mask__img" />').appendTo( $(el).find('.proj__media__visu') );
	var pc = $(el).find('.proj__media__visu').width() / $(window).width();
	
	tl.to( img_mask, pc * 1.5, {
		x: '-100%',
		ease: Power2.easeOut
	} );

	tl.from( $(el).find('.proj__media__txts'), 1, {
		x: 100,
		opacity: 0,
		ease: Power2.easeOut
	}, 0.5 );
	return tl;
});
