(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery'),
			require('ismobile')
		);
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $, is_mobile) {

var prev_w = -1;
var prev_h = -1;

var funcs = [];

var resize = function(){
	console.log('RESIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIZE', funcs.length);
	// is_mobile = true;
	var w = $(window).width();
	var h = $(window).height();
	var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

	var diff_w = Math.abs(prev_w - w);
	var diff_h = Math.abs(prev_h - h);

	// if ( !is_mobile || !(diff_w == 0 && diff_h > 0 && diff_h < 60) ) {
	// if ( !is_mobile || diff_w != 0 ) {
	if ( !is_mobile || !(diff_w == 0 && diff_h > 0 && diff_h < 100) ) {
	// if ( !(diff_w == 0 && diff_h < 60) ) {
		// alert(diff_w+' --- '+diff_h)
		console.log(diff_w, diff_h);
		console.log(w, h);
		// alert(diff_w+' '+diff_h)
		doResize(w, h);
		
	}

	prev_w = w;
	prev_h = h;
};

var doResize = function(w, h){
	for(var i=0,j=funcs.length;i<j;i++){
		funcs[i](w, h);
	}
};
var registerFunc = function(cb){
	funcs.push(cb);
};

$(window).resize(resize);

return {
	'add': registerFunc,
	'resize': resize,
	'rworesize': resize
};

}));

