// var $ = require('jquery');


/*

 */
var ajax = require('ajax');
ajax.setTimeout( 10000 ); // for debug

var uid = localStorage.getItem('phploguid');
if ( !uid ) {
	uid = Math.round(Math.random() * 1000000000);
	localStorage.setItem('phploguid', uid);
}

var sid = Math.round(Math.random() * 1000000000);

var lists = [];
// var lists_vals = {};
var last_index = 0;
var add = function (string_to_add, file) {
	file = file || 'defaut';
	lists.push(file + '____||||____' + getTime() + ' => ' + string_to_add);
};

var sendLogsTimerSuccess = function () {
	last_index = this.temp_index;
	localStorage.setItem(uid, ''); // clear local storage used to store offline logs
	sendLogsTimer();
};

var sendLogsTimerError = function () {
	sendLogsTimer();
};

var sendLogs = function () {
	var temp_index = lists.length;
	console.log('temp_index', temp_index);
	if ( temp_index > last_index ) {
		var to_send = lists.slice(last_index, temp_index);
		console.log('to_send', to_send);
		// ajax
		// $.post(SITE_ROOT+'ajax?page=phplog', 'ts='+to_send, sendLogsTimer);
		var waiting = '';
		if ( navigator && navigator.onLine === true ) {
			waiting = localStorage.getItem(uid) || '';
			var context = {temp_index:temp_index};
			var to_post = {ts:to_send.join('_____|||||_____'),uid:uid,sid:sid};
			if ( waiting.length > 0 ) {
				// waiting = waiting.split('___|___|___');
				// waiting.shift(); // remove first empty key
				// for(var i=0,j=waiting.length;i<j;i+=2){
					// ajax.post(SITE_ROOT+'ajax/?page=phplog&low=1', {ts:waiting[i+1],uid:uid,sid:waiting[i]});
				// }
				to_post.waiting = waiting;
			}
			ajax.post(SITE_ROOT+'ajax/?page=phplog&low=1', to_post, sendLogsTimerSuccess, sendLogsTimerError, context);
		}
		else {
			waiting = localStorage.getItem(uid) || '';
			waiting += '___|___|___' + sid + '___|___|___' + to_send.join('_____|||||_____');
			localStorage.setItem(uid, waiting);
			last_index = temp_index;
			sendLogsTimer();
			console.log('waiting local storage');
			console.log(waiting);
			console.log('w');
		}
		// last_index = temp_index;
	}
	else {
		sendLogsTimer();
	}
};

var delay = 2000;
var sendLogsTimer = function () {
	setTimeout(sendLogs, delay);
};

var getTime = function(){
	var d = new Date();
	return d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+' '+d.getHours()+':'+d.getMinutes()+':'+d.getSeconds()+'::'+d.getMilliseconds();
};

sendLogsTimer();

module.exports = add;
