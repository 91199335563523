var $ = require('jquery');
var reveals = require('./../0reveals');

reveals('proj-head', function(el, tl){

	tl.fromTo( $(el).find('.proj__head__bg'), 2, {
		// opacity: 0,
		transformOrigin: '75% 33%',
		scale: 1.05
	}, {
		scale: 1.001,
		// opacity: 1,
		ease: Power3.easeOut
	}, 0 )

	tl.fromTo( $(el).find('.proj__head__bg'), 1, {
		opacity: 0
	}, {
		opacity: 1,
		ease: Power1.easeInOut
	}, 0 )

	tl.addLabel( 'proj-head-next', 0.3 );

	tl.from( $(el).find('.proj__head__cta'), 0.8, {
		opacity:0,
		x:-100,
		ease: Power2.easeOut
	}, 'proj-head-next+=0.1' )


	var sp = new SplitText($(el).find('.proj__head__title'), {type:'words,chars'});


	tl.staggerFrom( $(sp.chars).wrapp('jq__mask dib').childs, 0.5, {
	    y: '100%',
	    // opacity: 0,
	    // immediateRender:false,
	    ease: Power1.easeOut
	}, 0.1, 'proj-head-next');


	// tl.staggerFromTo( $(sp.chars).wrapp('jq__masko dib').childs, 0.7, {
	//     scale: 2,
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, {
	//     scale: 1.001,
	//     opacity: 1,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, 0.075, 0.4);


	tl.from( $('.projs__item__subtitles__inner__inner'), 0.7, {
	    y: '100%',
	    opacity: 0,
	    // immediateRender:false,
	    ease: Power2.easeOut
	}, '-=0.5');
	
	


	// tl.staggerFromTo( $(sp.chars).wrapp('jq__masko dib').childs, 0.7, {
	//     scale: 2,
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, {
	//     scale: 1.001,
	//     opacity: 1,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, 0.075, 0.4);


	// tl.from( $('.home__subtitle').wrapp('jq__mask').childs, 0.7, {
	//     y: '100%',
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, '-=0.5');


	// tl.staggerFrom( $(el).find('.home__title, .home__subtitle'), 1, {
	//     x: 100,
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, 0.25, 0.4);

	return tl;
	// tl.from( $(el).find('.goback__cta__wrap'), 0.5, {
	// 	opacity: 0
	// 	// y: '100%'
	// } );

	var tttlb = new TimelineMax({paused:true});
	var path_lines = $(el).find('.goback__cta__path').get();
	tttlb.fromTo( path_lines.shift(), 1, {
	    drawSVG:"0% 0%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 0);
	tttlb.fromTo( path_lines, 0.2, {
	    drawSVG:"0% 0%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 1);
	tl.addLabel('ctaanim', 0.5)
	tl.fromTo( tttlb, 1, {
		progress: 0
	}, {
		progress: 1,
		ease: Power2.easeOut
	}, 'ctaanim+=0.2' );
	tl.fromTo( $(el).find('.goback__cta__mask'), 0.75, {
		scaleX: 1
	}, {
		scaleX: 0,
		ease: Power2.easeOut
	}, 'ctaanim+=0.3' );
	tl.set( $(el).find('.goback__cta__span').eq(0), {
		className: '-=can-animate'
	}, 'ctaanim+=0.3');
	tl.fromTo( $(el).find('.goback__cta__span').eq(0), 0.75, {
		y: '100%'
	}, {
		y: '0%',
		clearProps: 'all',
		ease: Power2.easeOut
	}, 'ctaanim+=0.4' );
	tl.set( $(el).find('.goback__cta__span').eq(0), {
		className: '+=can-animate'
	});
	return tl;
});
