(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		module.exports = factory(window, require('jquery'));
	} else {
		factory(window, jQuery);
	}

}(window, function(window, $) {
	
	var Plugin = function(container) {

		var bgs = $(container).find('.projs__bgs__visu').get();
		var prev_index = 0;

		var mouseover = function(){
			$(bgs[prev_index]).removeClass('is-active');
			prev_index = $(this).index();
			$(bgs[prev_index]).addClass('is-active');
		};

		var mouseout = function(){
			// TweenMax.to(tl, 1, {timeScale:1/spd});
		};

		$(container).find('.projs__item').hover(mouseover, mouseout);

	};

	// =======================
	$.fn['projetshover'] = function (option, args) {
		return this.each(function () {
			var name = 'plugin_' + 'projetshover';
			var data	= $.data(this, name);
			var opts = typeof option === "object" && option;
			if ( !data ) {
				$.data(this, name, (data = Plugin(this, opts)));
			}
			if (typeof option === "string") {
				data[option](args);
			}
		});
	};

}));

