var $ = require('jquery');
var reveals = require('./../0reveals');

reveals('home-boucle', function(el, tl){
	// alert(way)
	// var first_loading = $(el).find('.site__loader__185').size() ? true : false;
	// alert(first_loading)

	var tttlb = new TimelineMax({paused:true});
	tttlb.fromTo( $(el).find('.home__8__mask__path--bot'), 1, {
	    drawSVG:"100% 100%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 0.75);

	tttlb.fromTo( $(el).find('.home__8__mask__path--top'), 0.8, {
	    drawSVG:"100% 100%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeIn
	}, 0);

	tl.fromTo( tttlb, 3, {
	    progress:0
	}, {
	    progress:1,
	    // immediateRender:false,
	    ease: Power2.easeOut
	}, 0);

	return tl;
	// tl.from( $(el).find('.goback__cta__wrap'), 0.5, {
	// 	opacity: 0
	// 	// y: '100%'
	// } );

	var tttlb = new TimelineMax({paused:true});
	var path_lines = $(el).find('.goback__cta__path').get();
	tttlb.fromTo( path_lines.shift(), 1, {
	    drawSVG:"0% 0%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 0);
	tttlb.fromTo( path_lines, 0.2, {
	    drawSVG:"0% 0%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 1);
	tl.addLabel('ctaanim', 0.5)
	tl.fromTo( tttlb, 1, {
		progress: 0
	}, {
		progress: 1,
		ease: Power2.easeOut
	}, 'ctaanim+=0.2' );
	tl.fromTo( $(el).find('.goback__cta__mask'), 0.75, {
		scaleX: 1
	}, {
		scaleX: 0,
		ease: Power2.easeOut
	}, 'ctaanim+=0.3' );
	tl.set( $(el).find('.goback__cta__span').eq(0), {
		className: '-=can-animate'
	}, 'ctaanim+=0.3');
	tl.fromTo( $(el).find('.goback__cta__span').eq(0), 0.75, {
		y: '100%'
	}, {
		y: '0%',
		clearProps: 'all',
		ease: Power2.easeOut
	}, 'ctaanim+=0.4' );
	tl.set( $(el).find('.goback__cta__span').eq(0), {
		className: '+=can-animate'
	});
	return tl;
});
