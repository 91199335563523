(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		require('jquery-easing');
		// require('smoothscroll');
		require('es6-promise/auto');
		// require('resize');
		// require('scroll');
		// require('cyma-video');
		require('./sksmooth-anims.js');
		require('./sksmooth-funcs.js');
		require('gsap');
		require('gsap18/utils/SplitText.js');
		require('gsap18/plugins/DrawSVGPlugin');
		// require('appcache');
		require('fancybox');
		// require('./sticky.js');
		// require('./menuManager.js');
		// require('./contact.js');
		// require('./popup.js');
		require('./popupvideo.js');
		// require('formcheckajax');

		// impact modules
		require('jqfu-input');
		require('formcheckajaxsimple');
		require('share');
		require('accordeon');
		require('projfilterscta');
		require('shuffler');
		require('cymasmooth/config-defaut');

		// atelier 181
		require('frise-animation');
		require('gototop');
		require('scrollto');
		require('./projetshover.js');
		require('./anims/mask.js');

		module.exports = factory(
			window,
			document,
			require('jquery'),
			require('emresizer'),
			require('cymasmooth'),
			require('site'),
			// require('mainmenu'),
			require('fastclick'),
			require('ismobile'),
			require('./siteloader.js'),
			require('resize-wo-toolbar'),
			require('./menu-toggler.js')
			// require('./igcstripe.js') 
			// require('formcheck'),
			// require('form-input-placeholder') 
		);
	} else {
		factory(window, document, jQuery, cymasmooth);
	}

}(window, function(window, document, $, emresizer, cymasmooth, site, attachFastClick, is_mobile, siteloader, realResize, menuToggler) {

	$.fancybox.defaults.hash = false;
	$.fancybox.defaults.hideScrollbar = false;
	// disable poster cause fancybox use the image inside the link and its not what we wants
	$.fancybox.defaults.video.tpl = '<video class="fancybox-video" controls controlsList="nodownload" postero="{{poster}}">' +
      '<source src="{{src}}" type="{{format}}" />' +
      'Sorry, your browser doesn\'t support embedded videos, <a href="{{src}}">download</a> and watch with your favorite video player!' +
      "</video>";
	// $.fancybox.defaults.arrows = false;
	$.fancybox.defaults.buttons = [
		"zoom",
		//"share",
		// "slideShow",
		//"fullScreen",
		//"download",
		// "thumbs",
		"close"
	];

	// alert(fetcho)

	var windowResizeHandler = function(){
		// emresizer.resizeb( $('.home__slide__items__wrap') );
		// emresizer.resizeb( $('.page__txts'), false, $('.page__txts__wrap').width(), $('.page__txts__wrap').height() );
		// if ( $('.page__bg__wrap').size() ) {
		// 	$('.page__bg__wrap').css( 'left', $('.page__txts').offset().left + $('.page__txts').width() * 0.582 );
		// }
		// if ( $('.webapp__axo__inner').size() ) {
		// 	// console.log('w', $('.webapp__axo__inner').width());
		// 	// console.log('h', $('.webapp__axo__inner').height());
		// 	emresizer.resizeb( $('.webapp__axo__bg'), false, $('.webapp__axo__inner').width(), $('.webapp__axo__inner').height() );
		// }
		// emresizer.resizeb( $('.contact__wrap__inner') );
		// emresizer.resizeb( $('.main__popup__inner') );
		// emresizer.resizeb( $('.page__login__inner') );
		// emresizer.resizeb( $('.home__menu__wrap') );
		// // emresizer.resizeb( $('.head') );
		// emresizer.resizeb( $('.brochure__wrap__inner') );
		// emresizer.resizeb( $('.film__wrap__inner') );
		// emresizer.resizeb( $('.axo__wrap__inner') );
		// emresizer.resizeb( $('.main__popup__close__svg'), true );
		// emresizer.resizeb( $('.head'), true );
		// emresizer.resizeb( $('.home__wrap__inner') );
		// emresizer.resizeb( $('.diapo__wrap__inner') );
		// emresizer.resizeb( $('.contact__wrap__inner') );
		// emresizer.resize( $('.content__content').get(0) );
		// emresizer.resize( $('.site__mob__inner').get(0) );
		// emresizer.resize( $('.intro__apart').get(0), true ); // if true, only font size is applied
		// emresizer.resize( $('[data-js="frame-main-menu-inner"]').get(0) ); // if true, only font size is applied
		// emresizer.resize( $('[data-js="main-popup-inner"]').get(0) ); // if true, only font size is applied
	};
	// $(window).on("cube:resize", windowResizeHandler);
	realResize.add( windowResizeHandler );
	// $(window).on("resize", windowResizeHandler);
	windowResizeHandler();

	


	var globalInit = function(){

		if ( is_mobile ) {
			$('body').addClass('is-mobile');
		}
		else {
			$('body').addClass('is-desktop');
		}

		$( document ).on('click', '[data-js="close-popup"]', function(evt) {
			$.fancybox.close();
		});

		$( document ).on('click', '[data-js="scroll-to-contact"]', function(evt) {
			menuToggler();
			var target_query = $('.page__section--foot');
			$('html, body').animate({scrollTop:$(target_query).offset().top}, 50, 'easeInOutQuart');
		});

		siteloader(function(){
			site.initPage( $('html') );
			// cymasmooth.initCurrentPage();
		});
	};


	$(function(){
		if ( site.cymaSmooth ) {
			$('html, body').scrollTop(0);
			$('html').addClass('has-cymamooth');
		}

		globalInit();

		if ( site.cymaSmooth ) {

			// cymasmooth.enableHistory( true );
			cymasmooth.initCurrentPageSimple();
			setTimeout(function() {
				// cymasmoothcache.init('.sitemap a');
			}, 100);

		}

	});

	cymasmooth.registerFuncMulti( 'onRenderPage', function(url, record_visit, undefined){
		var html = $(this.next_page.html);
		var otherlanglink_href = $(html).find('[data-js="otherlanglink"]').attr('href');
		$('[data-js="otherlanglink"]').attr('href', otherlanglink_href);
		// // update head menu class
		// var index = html.find('.head__menu__item__link.is-current').parent().index();
		// $('.head__menu__item__link').removeClass('is-current');
		// if ( index >= 0 )  {
		// 	$('.head__menu__item').eq( index ).find('.head__menu__item__link').addClass('is-current');
		// }
	} );

	window.onbeforeunload = function() {
		window.scrollTo(0, 0);
	};

}));
