
var $ = require('jquery');
var FormCheck = require('formcheck');
// var cubepluginjq = require('./cubepluginjq.js');
// require('isotope-layout')

var form_succes_check = function(){};
var form_succes_done = function(){};

var init = function(el, form_succes_check_cb, form_succes_done_cb){
	$(el).find('.formcheck').each(function(i, form){
		new FormCheck(form, {
			errorsLocation: 1,
			indicateErrors: 2,
			showErrors: 1,
			addClassErrorToField: 1,
			submitNormally: false,
			submitByAjax: false,
			customSubmit: formSubmitted
		});
	});
	form_succes_check = form_succes_check_cb;
	form_succes_done = form_succes_done_cb;
	can_submit_form = true;
};

module.exports = init;
		

// var Plugin = function(container) {
	
// };

var can_submit_form = true;
var formSubmitted = function($form) {
	console.log('formSubmitted');
	if ( can_submit_form ) {
		can_submit_form = false;
		$form.parent().find('.form__msg__error').addClass('is-out');
		$form.parent().find('.form__msg__loading').removeClass('is-visible is-out');
		var delay = 0;
		if ( $form.parent().find('.form__msg__error').hasClass('is-visible') ) {
			delay = 700;
		}
		setTimeout(formSubmittedNext, delay, $form);
	}
};

var formSubmittedNext = function($form) {
	console.log('formSubmittedNext');
	$form.parent().find('.form__msg').removeClass('is-visible is-out');
	$form.parent().find('.form__msg__loading').addClass('is-visible');
	setTimeout(formSubmittedDelayed, 700, $form);
};



var formSubmittedDelayed = function($form) {
	console.log('formSubmittedDelayed');
	// $.post($form.attr('action'), $form.serialize(), formSubmittedRep);
	$.ajax({url: $form.attr('action'), type:'post', context:{form: $form}, data:$form.serialize(), success: formSubmittedRep});
};



var formSubmittedRep = function(data) {
	console.log('formSubmittedRep');
	var $form = this.form;
	$form.parent().find('.form__msg__loading').addClass('is-out');
	setTimeout(formSubmittedRepNext, 700, [$form, data]);
};

var formSubmittedRepNext = function(arr) {
	console.log('formSubmittedRepNext');
	console.log(arr);
	var $form = arr[0];
	var data = arr[1];
	// if ( $(data).find('.form__msg__error').size() ) {
	if ( !form_succes_check(data) ) {
		// $form.parent().find('.form__error__txt').addClass('is-visible');
		$(data).find('[data-js-form-dyn]').each(function(i, el){
			var k = el.getAttribute('data-js-form-dyn');
			var cur_el = $form.parent().find('[data-js-form-dyn="'+k+'"]').get(0);
			cur_el.setAttribute('name', el.getAttribute('name'));
			cur_el.value = el.value;
		});
		$form.parent().find('.form__msg__error').addClass('is-visible');
		can_submit_form = true;
	}
	else {
		$form.parent().find('.form__msg__success').addClass('is-visible');
		can_submit_form = form_succes_done( $form );
		// localStorage.setItem('islogged', 1);
		// // cymasmooth.goTo(window.location+'?user='+Math.round(Math.random() * 1000));
		// // cymasmooth.goTo(window.location+'home');
		// // cymasmooth.goTo(window.location+'');
		// setTimeout(function() {
		// 	TweenMax.to( $('.page__login__inner'), 1, {
		// 		opacity: 0
		// 	} );
		// }, 800);
		// setTimeout(function() {
		// 	window.location.reload();
		// }, 1800);
	}
};

// cubepluginjq( 'formgeneric', Plugin );



