require('jqfu');
var $ = require('jquery');

/*

todo : 
store files uploaded as a special list
special list is a list class
wich contain an array of object values
method : add, delete, clear, draw
 */

var Plugin = function (el) {
	this.input = el;
	this.input_id = $(this.input).attr('data-input-id');
	this.jqfu_limit = parseInt( $(this.input).attr('data-jqfu-limit'), 10 );

	this.file_input = $('[data-jqfu-file-input="'+this.input_id+'"]').get(0);
	this.panel = $('[data-jqfu-panel="'+this.input_id+'"]').get(0);

	if ( $(this.input).attr('data-panel-container') ) {
		$(this.panel).appendTo( $( $(this.input).attr('data-panel-container') ) );
	}

	this.files_ok = 0;
	// this.list.length();

	$(this.file_input).fileupload({
		url: SITE_ROOT+'jqfupl/'+$(this.input).attr('data-jqfu-action')+'/'+$(this.input).attr('id'),
		dataType: 'json',
		maxNumberOfFiles: this.jqfu_limit,
		start: $.proxy(this.___fileuploadStart, this),
		progressall: $.proxy(this.___fileuploadProgress, this),
		done: $.proxy(this.___fileuploadDone, this)
	});
};

var proto = Plugin.prototype;

proto.___fileuploadStart = function ( evt ) {
	$( this.panel ).find('.progress-bar').css({
		'width': '0%'
	});
	$( this.panel ).find('.jqfu__progress').addClass('is-visible');
};

proto.___fileuploadProgress = function ( evt, data ) {
	console.log('data.loaded', data.loaded, data.total);
	var progress = parseInt( data.loaded / data.total * 100, 10 );
	$( this.panel ).find('.progress-bar').css({
		'width': progress + '%'
	});
};

proto.___fileuploadDone = function ( evt, data ) {
	console.log('___fileuploadDone arguments', arguments);
	$( this.panel ).find('.jqfu__files').find('.error').remove(); // utile : oui : vire les erreurs qui sont genere juste en dessous
	if ( data && data.result.error === 0 ) {
		this.___fileuploadDoneSuccess( data );
	}
	else {
		this.___fileuploadDoneError( data );
	}
};

proto.___fileuploadDoneSuccess = function(data) {
	// this.list.add(data.result)
	var $panel = $( this.panel );
	this.files_ok += 1;
	if ( this.files_ok >= this.jqfu_limit ) {
		// hide or disable upload btn
		// $inputform.removeClass('mask-invisible');
	}

	if ( this.files_ok > this.jqfu_limit ) {
		return;
	}
	
	var $p = $('<p/>').text(data.result.name).appendTo($panel.find('.jqfu__files')).data('fname', data.result.fname).data('cname', data.result.name);
	// alert($p.data('fname'))
	// var $p = $('<p/>').html('<a target="_blank" href="'+data.result.previewurl+'"><img src="'+data.result.previewurl+'" style="width:100px;"/></a>').appendTo($panel.find('.jqfu__files')).data('locurl', data.result.locurl);
	// $('<span class="del_link"/>').text('×').appendTo($p).bind('click', function(){
	// $('<span class="del_link"/>')
	// 	.text('×')
	// 	.appendTo($p)
	// 	.data('delurl', data.result.delurl)
	// 	// .bind('click', delImg(panel, input_wrap));
	// 	.bind('click', $.proxy(this.___delImg, this));
	$panel.find('.progress-bar').removeClass('has-error');
	setTimeout(function() {
		$panel.find('.jqfu__progress').removeClass('is-visible');
	}, 300);

	// checkMainErrorDisplay();
	this.___updateRealInput();
};

proto.___fileuploadDoneError = function ( data ) {
	var $p = $('<p class="error" />').text(data.result.name).appendTo( $( this.panel ).find('.jqfu__files') );
	if ( data.result.errormsg ) {
		// var msg_infos = data.result.errormsg.split(';');
		// var msg = $('.'+msg_infos[0]).text();
		// msg = msg.replace('%KEY%', msg_infos[1]);
		$('<p class="error__msg" />').text(data.result.errormsg).appendTo($p);
	}
	// console.log($( panel ).find('.jqfu__files').size());
	if ( this.files_ok === 0 ) {
		// $( this.panel ).find('.progress-bar').addClass('has-error');
	}
};

proto.___delImg = function(e, panel, input_wrap) {
	var el = e.currentTarget;
	$.ajax({
		'url' : $(el).data('delurl')
		// 'el': el
	// }).done(delImgDone(el, panel, input_wrap));
	}).done($.proxy(this.___delImgDone, this, el));
};

proto.___delImgDone = function(data) {
	console.log('delImgDone arguments', arguments);
	var $p = $(this.el.parentNode);
	var $panel = $( this.panel );
	// var $inputform = $(this.el).closest('.formline').find('.inputform');
	$p.animate({'opacity':0}, 500, $.proxy(this.___delImgDone, this, $p));
};

proto.___delImgAnimDone = function(p) {
	// list.remove( index )
	$( p ).remove();
	this.files_ok = Math.max(0, this.files_ok - 1);
	if ( this.files_ok < this.jqfu_limit ) {
		// hide upload btn
		// $inputform.addClass('mask-invisible');
	}
	if ( this.files_ok === 0 ) {
		$( this.panel ).find('.progress-bar').css('width', 0);
	}
	this.___updateRealInput();
};

proto.___updateRealInput = function() {
	// list.redraw()
	var val = [];
	$( this.panel ).find('.jqfu__files p').each(function(i, el) {
		val.push( $(el).data('fname') );
		val.push( $(el).data('cname') );
	});
	this.input.value = val.join(';');
	// alert('here')
	// alert( 'aa' + this.input.value )
};

var initPage = function(evt, el){

	$(el).find('input[data-jqfu=1]').each(function(i, el){
		el['jqfuinput'] = new Plugin(el);
	});

};

$(window).on('site:initPage', initPage);
