var $ = require('jquery');
var reveals = require('./../0reveals');

reveals('ml-content', function(el, tl){

	tl.from( $(el).find('.ml__title'), 0.7, {
		x: '-100',
		opacity: 0,
		ease: Power2.easeOut
	}, 0 )

	tl.from( $(el).find('.ml__line'), 1, {
		transformOrigin: '0 0',
		scaleY: 0,
		ease: Power2.easeOut
	}, 0.1 )

	tl.from( $(el).find('.ml__txt'), 1, {
		x: '-100em',
		opacity: 0,
		ease: Power2.easeOut
	}, 0.35 )

	return tl;
	
	// var sp = new SplitText($(el).find('.home__title'), {type:'lines,words,chars'});
	var sp = new SplitText($(el).find('.proj__title'), {type:'lines'});


	// tl.staggerFrom( $(sp.chars).wrapp('jq__mask dib').childs, 0.7, {
	tl.staggerFrom( sp.lines, 0.7, {
	    x: '-100',
	    opacity: 0,
	    // immediateRender:false,
	    ease: Power2.easeOut
	}, 0.075, 0);

	

	tl.staggerFrom( $(el).find('.proj__tag'), 1, {
		y: 30,
		opacity: 0,
		ease: Power2.easeOut
	}, 0.1,  0.45 )


	// tl.staggerFromTo( $(sp.chars).wrapp('jq__masko dib').childs, 0.7, {
	//     scale: 2,
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, {
	//     scale: 1.001,
	//     opacity: 1,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, 0.075, 0.4);


	// tl.from( $('.home__subtitle').wrapp('jq__mask').childs, 0.7, {
	//     y: '100%',
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, '-=0.5');


	// tl.staggerFrom( $(el).find('.home__title, .home__subtitle'), 1, {
	//     x: 100,
	//     opacity: 0,
	//     // immediateRender:false,
	//     ease: Power2.easeOut
	// }, 0.25, 0.4);

	return tl;
	// tl.from( $(el).find('.goback__cta__wrap'), 0.5, {
	// 	opacity: 0
	// 	// y: '100%'
	// } );

	var tttlb = new TimelineMax({paused:true});
	var path_lines = $(el).find('.goback__cta__path').get();
	tttlb.fromTo( path_lines.shift(), 1, {
	    drawSVG:"0% 0%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 0);
	tttlb.fromTo( path_lines, 0.2, {
	    drawSVG:"0% 0%"
	}, {
	    drawSVG:"0% 100%",
	    // immediateRender:false,
	    ease: Power0.easeOut
	}, 1);
	tl.addLabel('ctaanim', 0.5)
	tl.fromTo( tttlb, 1, {
		progress: 0
	}, {
		progress: 1,
		ease: Power2.easeOut
	}, 'ctaanim+=0.2' );
	tl.fromTo( $(el).find('.goback__cta__mask'), 0.75, {
		scaleX: 1
	}, {
		scaleX: 0,
		ease: Power2.easeOut
	}, 'ctaanim+=0.3' );
	tl.set( $(el).find('.goback__cta__span').eq(0), {
		className: '-=can-animate'
	}, 'ctaanim+=0.3');
	tl.fromTo( $(el).find('.goback__cta__span').eq(0), 0.75, {
		y: '100%'
	}, {
		y: '0%',
		clearProps: 'all',
		ease: Power2.easeOut
	}, 'ctaanim+=0.4' );
	tl.set( $(el).find('.goback__cta__span').eq(0), {
		className: '+=can-animate'
	});
	return tl;
});
