var $ = require('jquery');
var formgeneric = require('formgeneric');

var initPage = function(evt, el){

	$(el).find('[data-js="formcontact"]').each(function(i, eel){
		formgeneric( eel, function(data){
			return $(data).find('.contactreturn').size() > 0;
		}, function(){
			$('.formcheck').removeClass('is-loading').addClass('is-disabled');
			return false; // set can_submit_form to false
		} );
	});

};

$(window).on('site:initPage', initPage);
