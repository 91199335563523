var $ = require('jquery');
var reveals = require('./../0reveals');

reveals('scrolldown-mask', function(el, tl){
	
	tl.to( $(el).find('.home__page__scrolldown__mask'), 1, {
		transformOrigin: '100% 100%',
		scaleY: 0,
		ease: Power2.easeOut
	}, 0.9 )
	
	tl.from( $(el).find('.home__page__scrolldown__arrow'), 1, {
		y: '-100%',
		opacity: 0,
		ease: Power2.easeOut
	}, 1.3 )
	return tl;
});
