var $ = require('jquery');

$( document ).on('click', '[data-acc-item-toggler]', function(evt) {
	var accordeon_item_id = $(this).attr('data-acc-item-toggler');
	var parent = $(this).parents('[data-acc-item-parent]').get(0);
	var accordeon_group = $(parent).attr('data-acc-item-parent');
	// check if there is other node already open
	var $el_open = $('[data-acc-item-parent="'+accordeon_group+'"]').filter('.is-open');
	if ( parent === $el_open.get(0) ) {}
	else {
		$el_open.removeClass('is-open');
		$el_open.find('[data-acc-item-target]').slideToggle();
	}
	$(parent).toggleClass('is-open');
	$('[data-acc-item-target="'+accordeon_item_id+'"]').slideToggle();
});

