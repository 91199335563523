(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		// require('history');
		module.exports = factory(
			window,
			require('jquery'),
			require('utils')
		);
	} else {
		window.cymasmooth = factory(window, jQuery);
	}

}(window, function(window, $, utils) {

var C = (function() {
	var cache = {};
	return function(selector, remove) {
		if (typeof remove !== 'undefined' && cache[selector]) {
			delete cache[selector];
		}
		if (!cache[selector]) {
			cache[selector] = document.getElementById(selector);
		}
		return cache[selector];
	};
})();

var can_use_history = false;
$(function(){
	// can_use_history = true;
});

var has_promise = (window.Promise);

var SkSmooth2 = function(){
	/**
	* Data stored for cache as `url => data`
	* @property pages
	* @type Array
	*/
	var pages = [];

	/**
	* Anims to be loaded matching current model
	* @property Anims
	* @type Object
	*/
	var Anims = {};

	/**
	* Page to be hidden during page change Object with params : model,submodel,url,uid,div,subdiv
	* @property prev_page
	* @type Object
	*/
	this.prev_page = {'model':null,'submodel':null,'url':null,'uid':null,'subdiv':null,'div':null};
	
	/**
	* Page to be shown during page change Object with params : model,submodel,url,uid,div,subdiv
	* @property next_page
	* @type Object
	*/
	this.next_page = {'model':null,'submodel':null,'url':null,'uid':null,'subdiv':null,'div':null};

	/**
	* Element used to store data
	* @property cacheDiv
	* @type DomNode
	* @default div
	*/
	// var cacheDiv = new Element('div');
	var cacheDiv = document.createElement('div');
	// alert(cacheDiv)

	var funcs = {};
	/**
	* Call if new page to display need an ajax request
	* This method is call before request
	* (usefull to add custom loading manager)
	* @Method onAjaxLoadStart
	*/
	funcs['onAjaxLoadStart'] = null;
	

	/**
	* Call if new page to display need an ajax request
	* This method is call after request
	* (usefull to add custom loading manager)
	* @Method onAjaxLoadEnd
	*/
	funcs.onAjaxLoadEnd = null;

	/**
	* Div id to be shown/hidden during ajax loading
	* @property ajaxLoadingId
	* @type String
	* @default null
	*/
	var ajaxLoadingId = null;

	this.animatorObj = false;

	var sksmoothdebug = true;

	this.firstPass = true;
	this.pagesViewed = 0;

	this.enableHistory = function(val) {
		can_use_history = val;
	};

	this.registerFunc = function(name, func) {
		funcs[name] = func;
	};

	this.registerAnim = function(name, func) {
		Anims[name] = func;
	};

	this.callFunc = function(name, a, b, c) {
		funcs[name](a, b, c);
	};

	this.defaultUrlQuery = 'intro';

	// this.contentQuery = '.site-content-inner';
	this.contentQuery = '[data-sksmooth="page-content"]';

	var that = this;

	funcs.ajaxLoad = function( url ) {
		// un bloc comme ca a besoin de : 
		// 1 une fonction de preproc unique
		// 2 des fonctions de preproc qui peuvent etre multiple
		// 3 un event preproc sur window
		// 
		// 4 une fonction bloquante unique
		// 5 une fonction avec callback unique (pas utile)
		// 6 des fonctions avec callback
		if ( funcs.onAjaxLoadFirstStart )
			funcs.onAjaxLoadFirstStart();

		// if ( pages[url] && pages[url].div.getAttribute('data-skmooth-nocache') != '1' ){
		// 	return renderPage(url);
		// }

		if ( funcs['onAjaxLoadStart'] ) {
			funcs['onAjaxLoadStart']();
		}

		var can_continue = true;
		if ( funcs['onAjaxLoadBlocking'] ) {
			can_continue = funcs['onAjaxLoadBlocking'].call(this, url);
		}
		// console.log('can_continue', can_continue);
		if ( can_continue ) {

			$.ajax({url: url, context:{url: url}, success: this.ajaxSuccessCB( url )});
		}
	};

	this.ajaxLoad = function( url ){
		funcs.ajaxLoad.call( this, url );
	};

	this.ajaxSuccessCB = function(url){
		return function( data ) {
			this.ajaxSuccess( data, url );
		}.bind( this );
	};

	this.ajaxSuccess = function(data, url){
		
		var html = document.createElement( 'div' );
		html.innerHTML = data;

		this.registerPage(url, html);
		this.renderPage(url);
	};

	this.initCurrentPage = function(){
		var url = window.location.toString();
		can_use_history = true;

		this.registerPage(url, $('html').get(0));
		this.renderPage(url, false);
	};

	this.initCurrentPageSimple = function(){
		var url = window.location.toString();
		can_use_history = true;

		this.registerPage(url, $('html').get(0));
		this.updatePages(url);
	};

	this.registerPage = function(url, html){
		var final_el = $(html).find(this.contentQuery).get(0);


		if ( funcs.onPageRegistered )
			funcs.onPageRegistered(html, final_el);

		var datas = {
			url: url,
			html: html,
			el: final_el
		};

		if ( funcs.onPageRegisteredB )
			funcs.onPageRegisteredB( datas );

		pages[datas.url] = {
			'model': $(datas.el).data('model'),
			'url': datas.url,
			'uid': $(datas.el).data('uid'),
			'div': datas.el,
			'title': $(datas.html).find('title').html(),
			'html': datas.html
		};
	};

	this.updatePages = function(url){
		this.prev_page = this.next_page;
		this.next_page = pages[url];
	};

	// record_visit is an optionnal parameter, by default the script record visit
	// param added to avoid recording it when this function is triggered on the first site loading
	this.renderPage = function(url, record_visit, undefinded){
		console.log('renderPage');
		// console.log('sec');
		// alert(has_promise)
		$(window).trigger('cymasmooth:renderPage');
		
		if ( funcs.onAjaxLoadEnd )
			funcs.onAjaxLoadEnd();

		this.updatePages(url);

		// if ( record_visit !== undefinded && record_visit === false ) {
		if ( record_visit === undefinded || record_visit === true ) {
			// Visit record
			// recordVisit(this.prev_page.url || '', this.next_page.url);
			if ( typeof _gaq != 'undefined' ) {
				_gaq.push(['_trackPageview'], this.next_page.url);
			}

			if ( window['GoogleAnalyticsObject'] && window[window['GoogleAnalyticsObject']] ) {
				window[window['GoogleAnalyticsObject']]('send', 'pageview');
			}

			if ( window['GAnonymObject'] && window[window['GAnonymObject']] ) {
				window[window['GAnonymObject']]('send', 'pageview');
			}

			if ( !this.firstPass && window.Base64UrlEncode ) {
				// new Image().src = SITE_ROOT+'visitgarecord/v'+Base64UrlEncode(this.prev_page.url || '')+'/v'+Base64UrlEncode(this.next_page.url)+'/'+Math.round(Math.random()*99999999);
			}
		}


		this.firstPass = false;
		this.pagesViewed++;
		// alert( 's' )
		funcs.onStart(funcs.getHideAnim);
	};

	funcs.onStart = function(callback){callback();};
	
	funcs.getHideAnim = function(){
		var anim = new SkSmooth2CSS3Animator();
		anim.setOnComplete( function(){
			funcs.onHalf(funcs.getShowAnim);
		} );
		
		var anim_datas = [];
		// try {
			if ( Anims[that.prev_page.model+'_hide'] ) {
				anim_datas = Anims[that.prev_page.model+'_hide'](that.next_page, that.prev_page);
			}
			else {
				anim_datas = Anims['hide'](that.next_page, that.prev_page);
			}
		// } catch(e){}

		anim.fullAdd(anim_datas);

		that.animatorObj = anim;
		funcs.onHide(that.animatorObj.play);
	};

	funcs.onHide = function(callback){
		// console.log(that.next_page);
		document.title = that.next_page.title;
		console.log('funcs.updateMenus', funcs.updateMenus);
		funcs.updateMenus(that.next_page.div);
		if ( funcs.updateFromHtml ) {
			funcs.updateFromHtml(that.next_page);
		}
		callback();
	};

	funcs.updateMenus = function(){
		var id = that.next_page.div.getAttribute('data-menu-id');
		var $menus = C('menus');
		if ( $menus ) {
			jQuery($menus).find('a.current').removeClass('current');
			jQuery($menus).find('a.'+id).add('current');
		}
	};
	
	funcs.onHalf = function(callback){callback();};

	funcs.getShowAnim = function(){
		var is_mobile = /ipad|iphone|ipod|android|blackberry|windows phone|opera mini|silk/i.test(navigator.userAgent);
		var anim = new SkSmooth2CSS3Animator();
		anim.setOnComplete( funcs.onEnd );
		var anim_datas = false;
		// try {
			if ( is_mobile && Anims[that.next_page.model+'_show_mobile'] ) {
				// anim_datas = Anims[that.next_page.model+'_show'](that.next_page, that.prev_page);
				anim_datas = Anims[that.next_page.model+'_show_mobile'].call(that, that.next_page, that.prev_page);
			}
			else if ( Anims[that.next_page.model+'_show'] ) {
				// anim_datas = Anims[that.next_page.model+'_show'](that.next_page, that.prev_page);
				anim_datas = Anims[that.next_page.model+'_show'].call(that, that.next_page, that.prev_page);
			}
			else {
				// anim_datas = Anims['show'](that.next_page, that.prev_page);
				anim_datas = Anims['show'].call(that, that.next_page, that.prev_page);
			}
		// } catch(e){
		// 	if ( sksmoothdebug && sksmoothdebug === true ) {
		// 		alert('pb : Show anim for model : "' + that.next_page.model + '" not founded');
		// 		alert(e.message);
		// 	}
		// }
		// if ( has_promise ) {
		// 	var onShow_deferred = utils.deferred();			
		// 	Promise.all([onShow_deferred.promise]).then(function(){
		// 		// anim_datas.add(funcs.onEnd, '+=0');
		// 		// anim_datas.play();

		// 		var onEnd_deferred = utils.deferred();			
		// 		anim_datas.add(onEnd_deferred.resolve);
		// 		anim_datas.play();
		// 		Promise.all([onEnd_deferred.promise]).then(function(){
		// 			console.log('ty');
		// 			funcs.onEnd();
		// 		});
		// 	});
		// 	funcs.onShow(that.next_page.div, onShow_deferred.resolve);
		// }
		// else {

			anim.fullAdd(anim_datas);
			
			that.animatorObj = anim;
			funcs.onShow(that.next_page.div, that.animatorObj.play);
		// }
	};
	/**
	* Call before playing Show animation which enable play
	* 
	* @Method onShow
	* @param callback {Method} SkSmooth.onShowCB
	*/
	funcs.onShow = function(next_page, callback){

		var el = that.next_page.subdiv || that.next_page.div;
		var preload_id = el.getAttribute('data-preload-id');
		var imgs = [];
		if ( preload_id && preload_id.length > 0 && el.getAttribute('preload') != '1' && C(preload_id)){
			imgs = C(preload_id).getElementsByTagName('img');
		}
		if ( imgs.length > 0 ){
			imagesLoaded( imgs, function() {
				// alert(imgs.length)
				el.setAttribute('preload', '1');
				// $('loadingpage').removeClass('visible');
				// disableLoading();
				callback();
			});
		}
		else {
			// $('loadingpage').removeClass('visible');
			// disableLoading();
			callback();
		}
	};
	/**
	* Call after Show animation
	* 
	* @Method onEnd
	* @param callback {Method} SkSmooth.onEndCB
	*/
	funcs.onEnd = function(){
		
	};

	funcs.goTo = function( url ){
		History.pushState(null, null, url);
		this.onStateChange();
	};

	this.actionfrombrowser = false;
	this.comingfromgoto = false;
	
	this.goTo = function(url) {
		this.comingfromgoto = true;
		funcs.goTo.call( this, url );
	};

	this.onStateChange = function() {
		// alert('state changed')
		this.actionfrombrowser = !this.comingfromgoto;
		this.comingfromgoto = false;
		console.log('can_use_history', can_use_history);
		if (can_use_history) {
			this.ajaxLoad( window.location.toString() );
		}
		$(window).trigger('cymasmooth:statechanged');
	};

	this.getHref = function(el) {
		if (!el) {
			return undefined;
		}

		if (el.getAttribute && typeof el.getAttribute('xlink:href') === 'string') {
			return el.getAttribute('xlink:href');
		}

		if (typeof el.href === 'string') {
			return el.href;
		}

		return undefined;
	};

	this.onLinkClicked = function(evt) {
		var el = evt.target;
		while (el && !this.getHref(el)) {
			el = el.parentNode;
		}
		if ( funcs.linkClickedForce ) {
			return funcs.linkClickedForce(evt, el);
			// return false;
		}
		if ( $(el).attr('target') == '_blank' ) return true;
		if ( $(el).attr('data-no-ajax') == '1' ) return true;
		// disabled for mobile device
		if ( window.matchMedia("only screen and (max-width: 767px)").matches ) {
			return true;
		}

		if ( funcs.linkClicked ) {
			var ret = funcs.linkClicked($(el));
			if ( ret === true ) {
				// alert('will return true')
				return true;
			}
		}
		evt.preventDefault();
		var url = $(el).attr('href');
		url = url.split('#');
		url = url[0];
		console.log('url', url, History.getState().url, funcs.onSameUrlClicked);
		if ( url == History.getState().url ) {
			if ( funcs.onSameUrlClicked ) {
				return funcs.onSameUrlClicked(evt, el);
				// return false;
			}
		}
		this.goTo( url );
		History.pushState(null, null, url);
	};

	window.addEventListener( 'popstate', this.onStateChange.bind(this) );
	$( document ).on( 'click', 'a', this.onLinkClicked.bind(this) );
};

/**
* Animator class working with css3 anims  	
* which support onComplete callback  	
*
* animation array structure :
* 
* 	[0] => element to animate
* 	[1] => properties to animate
* 	[2] => duration
* 	[3] => callback method
* 	[4] => transition (String)
* 	[5] => delay || 0
*
* timing in milliseconds
* 
* @class SkSmooth.Animator
*/
var _raf = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          window.oRequestAnimationFrame      ||
          window.msRequestAnimationFrame     ||
          function(/* function */ callback, /* DOMElement */ element){
              window.setTimeout(callback, 1000 / 60);
          };
})();


var SkSmooth2CSS3Animator = function(){
	var anim = false;
	var onComplete = null;
	var animsLoaded = 0;
	// var that = this;
	this.fullAdd = function(tl){
		anim = tl;
	};
	this.setOnComplete = function(func){
		onComplete = func;
	};
	this.play = function(){
		// console.log('anim', anim);
		if ( anim ) {
			anim.add(onComplete, '+=0');
			_raf(function(){
				anim.play();
			});
		}
		else {
			onComplete();
		}
	};
	this.stopAndComplete = function(){
		// console.log('anim', anim);
		if ( anim ) {
			anim.time( anim.duration() );
		}
	};
};

// return new SkSmooth2();
return SkSmooth2;

}));


// mySingleton.echoName();
// mySingleton.echoName();
// mySingleton.echoName();