/*! atomic v1.0.0 | (c) 2015 @toddmotto | github.com/toddmotto/atomic */
(function(window, factory) { 'use strict';
	// if (typeof exports !== 'undefined') {
		module.exports = factory(
			window
		);
	// } else {
	// 	factory(window, jQuery);
	// }

}(window, function(window) {

	'use strict';

	var exports = {};

	var config = {
		contentType: 'application/x-www-form-urlencoded',
		timeout: 0 // 0 = no timeout
	};

	var parse = function (req) {
		var result;
		try {
			result = JSON.parse(req.responseText);
		} catch (e) {
			result = req.responseText;
		}
		return [result, req];
	};

	var getOnreadystatechange = function(request, success, error, context) {
		return function() {
			var req;
			if (request.readyState === 4) {
				req = parse(request);
				if (request.status >= 200 && request.status < 300) {
					success.apply(context, [req, context]);
				} else {
					error.apply(context, [req, context]);
				}
				request = null;
				// methods.always.apply(methods, req);
			}
		};
	};

	var getOntimeout = function(request, success, error, context) {
		return function() {
			error.apply(context, [false, context]);
			request = null;
		};
	};

	var xhr = function (type, url, data, success, error, context) {
		// var methods = {
		// 	success: function () {},
		// 	error: function () {}
		// };
		var XHR = window.XMLHttpRequest || ActiveXObject;
		var request = new XHR('MSXML2.XMLHTTP.3.0');

		var data_url = '';
		// console.log('data', data);
		for(var key in data) {
			data_url += (data_url.length == 0) ? '' : '&';
			data_url += key+'='+data[key];
		}
		// console.log(data_url);
		if ( type == 'GET' ) {
			if ( data_url.length > 0 ) {
				data_url = '?'+data_url;
			}
			url += data_url;
		}

		context = context || {};
		context.xhr_url = context.xhr_url || url;
		context.xhr_data = context.xhr_data || data;

		// console.log('url', url);

		request.open(type, url, true);
		request.setRequestHeader('Content-type', config.contentType);
		request.timeout = config.timeout;
		request.ontimeout = getOntimeout(request, success, error, context);
		request.onreadystatechange = getOnreadystatechange(request, success, error, context);
		// request.onreadystatechangeo = function () {
		// 	var req;
		// 	if (request.readyState === 4) {
		// 		req = parse(request);
		// 		if (request.status >= 200 && request.status < 300) {
		// 			success.apply(context, req);
		// 		} else {
		// 			error.apply(context, req);
		// 		}
		// 		// methods.always.apply(methods, req);
		// 	}
		// };
		request.send(data_url);

		// var atomXHR = {
		// 	success: function (callback) {
		// 		methods.success = callback;
		// 		return atomXHR;
		// 	},
		// 	error: function (callback) {
		// 		methods.error = callback;
		// 		return atomXHR;
		// 	},
		// 	always: function (callback) {
		// 		methods.always = callback;
		// 		return atomXHR;
		// 	}
		// };

		// return atomXHR;
	};

	exports.get = function (url, data, success, error, context) {
		xhr('GET', url, data, success, error, context);
	};

	exports.put = function (url, data) {
		return xhr('PUT', url, data);
	};

	// exports.post= function (url, data) {
	// 	return xhr('POST', url, data);
	// };

	exports.post = function (url, data, success, error, context) {
		xhr('POST', url, data, success, error, context);
	};

	exports.delete = function (url) {
		return xhr('DELETE', url);
	};

	exports.setContentType = function(value) {
		config.contentType = value;
	};

	exports.setTimeout = function(value) {
		config.timeout = value;
	};

	return exports;

}));