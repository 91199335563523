var $ = require('jquery');

module.exports = function(evt, el, url){
	if ( this.animatorObj ) {
		console.log('stop and complete animation');
		this.animatorObj.stopAndComplete();
	}
	if ( $( el ).hasClass( 'prod__cat__item__link' ) ) {
		$( el ).addClass( 'link-with-mover-effect' );
	}
};