(function(window, factory) {
	'use strict';
	// if (typeof exports !== 'undefined') {
		module.exports = factory(
			window,
			require('jquery'),
			require('phplog')
		);
	// } else {
	// 	factory(window, jQuery);
	// }

}(window, function(window, $, phplog) {
	return function(){
		// In the following line, you should include the prefixes of implementations you want to test.
		window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
		// DON'T use "var indexedDB = ..." if you're not in a function.
		// Moreover, you may need references to some window.IDB* objects:
		window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || {READ_WRITE: "readwrite"}; // This line should only be needed if it is needed to support the object's constants for older browsers
		window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
		// (Mozilla has never prefixed these objects, so we don't need window.mozIDB*)

		var db_name = 'keyval-storeab';
		var db_version = 1;
		var table_name = 'keyvald';
		var db;
		var request;
		var customerObjectStore;

		var dbOnError = function( event ) {
			console.log("Database error: " + event.target.errorCode);
		};

		var reqOnError = function( event ) {
			console.log("You didn't allow the web app to use IndexedDB");
		};

		var reqOnUpgradeNeeded = function( event, cb ) {
			// console.log('reqOnUpgradeNeeded');
			// Save the IDBDatabase interface 
			db = event.target.result;

			// Create an objectStore for this database
			// var objectStore = db.createObjectStore( table_name );
			db.createObjectStore( table_name );
		};

		var reqOnSuccess = function( event, cb ) {
			// console.log('reqOnSuccess');
			// Save the IDBDatabase interface 
			db = event.target.result;
			db.onerror = dbOnError;
			reqOnSuccessNext( cb );
		};

		var reqOnSuccessNext = function( cb ) {
			customerObjectStore = db.transaction( table_name, "readwrite").objectStore( table_name );
			cb( customerObjectStore );
		};

		var reqOnSuccessCB = function( cb ) {
			return function( event ) {
				reqOnSuccess( event, cb );
			};
		};

		var reqOnUpgradeNeededCB = function( cb ) {
			return function( event ) {
				reqOnUpgradeNeeded( event, cb );
			};
		};

		var openDB = function( cb ) {
			try {
				request = window.indexedDB.open( db_name, db_version );
				var exist = request ? 'true' : 'false';
				phplog('fdb : openDB() request exist : ' + exist);
				request.onerror = reqOnError;
				request.onsuccess = reqOnSuccessCB( cb );
				request.onupgradeneeded = reqOnUpgradeNeededCB( cb );
			} catch(e){
				phplog('fdb : openDB() error');
			}

		};

		var getCO = function( cb ) {
			if ( customerObjectStore ) {
				// cb( customerObjectStore );
				reqOnSuccessNext( cb );
			}
			else {
				openDB( cb );
			}
		};

		var get = function( key, cb, a, b, c ) {
			getCO( function(customerObjectStore){
				// var customerObjectStore = db.transaction( table_name, "readwrite").objectStore( table_name );
				var ret = customerObjectStore.get( key );
				ret.onsuccess = function( event ) {
					cb( event.target.result, a, b, c );
				};
			} );

		};

		var put = function( key, value, cb ) {
			getCO( function(customerObjectStore){
				// var customerObjectStore = db.transaction( table_name, "readwrite").objectStore( table_name );
				var ret = customerObjectStore.put( value, key );
				if ( cb ) {
					ret.onsuccess = function( event ) {
						cb( event.target.result, event );
					};
				}
			} );

		};

		return {
			get: get,
			put: put
		};
	}();



}));