var $ = require('jquery');

var is_open = false;

var ttl = new TimelineMax({
    paused : true,
    onComplete: function(){}
});

ttl.fromTo( $('.head__menu__bg'), 1, {
	y: '100%'
}, {
	y: '0%',
	ease: Power1.easeOut
});

// ttl.fromTo( $('.head__menu__bg'), 1, {
// 	x: '100%'
// }, {
// 	x: '0%',
// 	ease: Power1.easeOut
// });

ttl.fromTo( $('.head__menu__mask__path'), 1, {
	drawSVG:"100% 100%"
}, {
    drawSVG:"0% 100%",
	ease: Power1.easeOut
}, '-=0.5');

ttl.fromTo( $('.head__menu__bgs__bloc'), 1, {
	transformOrigin: "0 0",
	scaleY:0
}, {
    scaleY:1,
	ease: Power1.easeOut
}, '-=0.9');

ttl.staggerFromTo( $('.head__menu__item, .head__socs__item, .head__footer__ml, .head__footer__copy'), 1, {
	opacity: 0,
	x: 50
}, {
    opacity: 1,
	x: 0,
	ease: Power2.easeOut
}, 0.1, '-=0.5');




var toggleMenu = function(fast){
	// alert('b')
	if ( !is_open ) {
		TweenMax.fromTo( ttl, 3.5, {
			progress: 0
		}, {
			progress: 1,
			ease: Power3.easeOut
		} );
		// tl.timeScale(1);
		// tl.play();
		// if on the home page, juste animate menu items
		// if ( $('.home__nav__items').size() ) {
		// 	var tttls = [];
		// 	$('.home__nav__items .head__menu__item').each(function(i, el){
		// 		var tl = new TimelineMax({paused:true});
		// 		tl.to( el, 1, {
		// 			scale: 1.15,
		// 			ease: Power0.easeIn
		// 		} );
		// 		tl.to( el, 1, {
		// 			scale: 1.01,
		// 			ease: Power0.easeOut
		// 		} );
		// 		tttls.push( tl );
		// 	});
		// 	var tttl = new TimelineMax({paused:true});
		// 	tttl.staggerTo( tttls, 1.3, {
		// 		progress: 1,
		// 		ease: Power1.easeOut,
		// 		cycle:{
		// 	        duration:function(i, target) { //<-- this is the key. 
		// 	            return 1.7 - ( i * 0.2 );
		// 	        }
		// 	    }
		// 	}, 0.2 );
		// 	tttl.timeScale(1.5);
		// 	tttl.play();
		// 	return;
		// }
		// else {
			// TweenMax.fromTo( ttl, 1, {
			// 	progress: 0
			// }, {
			// 	progress: 1,
			// 	ease: Power0.easeOut
			// } );
		// }
	}
	else {
		TweenMax.fromTo( ttl, 1, {
			progress: 1
		}, {
			progress: 0,
			ease: Power3.easeOut
		} );
		// tl.timeScale(2);
		// tl.reverse();
		// var duration = fast === true ? 0.01 : 0.6;
		// TweenMax.fromTo( ttl, duration, {
		// 	progress: 1
		// }, {
		// 	progress: 0,
		// 	ease: Power0.easeOut
		// } );
	}
	is_open = !is_open;
	$('body').toggleClass( 'is-main-menu-open' );
};


var closeMenu = function( fast ){
	if ( is_open ) {
		toggleMenu( fast );
	}
};

// alert('a')
$( document ).on('click', '[data-js="toggle-main-menu"]', toggleMenu);
$(window).on('cymasmooth:onAjaxLoad', closeMenu);


module.exports = closeMenu;