(function(window, factory) { 'use strict';
	if (typeof exports !== 'undefined') {
		require('history');
		require('gsap');
		require('./popupvideo.js');
		// require('gsap18/plugins/MorphSVGPlugin');
		module.exports = factory(
			window,
			require('jquery'),
			require('cymasmooth'),
			require('./site.js'),
			require('imagesloadedwithsize'),
			// require('./head-svg-mover.js'),
			require('ismobile'),
			require('nprogresscustom'),
			require('raf'),
			require('./menu-toggler.js'),
			require('./anims/paralax.js')
			// require('mainmenu')
		);
	} else {
		factory(window, jQuery, window.cymasmooth, imagesLoaded);
	}

}(window, function(window, $, cymasmooth, site, imagesLoaded, is_mobile, NProgress, raf, menuToggler, paralax) {

if ( !site.cymaSmooth ) {
	return;
}

var debug = false;

var last_el_clicked = false;

var first_pass = false; // pass useless

var first_loading = true;
var total_to_load = 0;

// cymasmooth.registerFunc('onEnd', function(cb){
// 	console.log('onend');
// });
cymasmooth.registerFunc('onSameUrlClicked', function(){
	// alert('z')
	cymasmooth.initCurrentPage();
});

}));